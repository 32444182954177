import React, { useEffect, useRef } from "react";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckBoxContainer } from "../../../style/common.style";
import {
  onchangeBillrate,
  onchangeSelectedPartnerList,
  onchangeSelectedPartnerTaxList,
} from "../../../feature/store/ClientReduxStore";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function PatnerSelectionComponent(props) {
  const inputClick = useRef();
  const dispatch = useDispatch();
  const [selectedPartnerList, setSelectedPartnerList] = React.useState([]);
  const temp_partner_data = useSelector(
    (state) => state.ClientReduxStore.temp_partner_data
  );
  const selected_partner_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_list
  );
  const selected_partner_tax_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_tax_list
  );
  const bill_rate = useSelector((state) => state.JobReduxStore.bill_rate);

  useEffect(() => {
    setSelectedPartnerList([]);
    preFieldData();
  }, [temp_partner_data]);

  async function preFieldData() {
    var arr = [];
    if (!props.preFieldData && props.preFieldData !== undefined) {
      return;
    }
    await temp_partner_data.map((data) => arr.push(data?.name));
    setSelectedPartnerList(arr);
  }
  function onActionData(singleItem) {
    if (singleItem == null) return;
    var isExist = selected_partner_list.some(
      (item) => item.id === singleItem.id
    );
    if (isExist) {
      const filtered = selected_partner_list.filter((item) => {
        return item.id !== singleItem.id;
      });
      const filteredX = selected_partner_tax_list.filter((item, index) => {
        return item.partner_id !== singleItem.id;
      });
      dispatch(onchangeSelectedPartnerTaxList(filteredX));
      dispatch(onchangeSelectedPartnerList(filtered));
      let newData = selectedPartnerList.filter(function (singleData) {
        return singleData !== singleItem?.name;
      });
      setSelectedPartnerList(newData);
    } else {
      var tempTaxData = {
        partner_id: singleItem.id,
        name: singleItem.name,
        recruter_id: "",
        recruiter_name: "",
        _1099: 0,
        w2_with_benifits: 0,
        w2_without_benifit: 0,
        w2_salarized: 0,
        margin: "",
      };
      selectedPartnerList.push(singleItem?.name);
      dispatch(
        onchangeSelectedPartnerTaxList([
          ...selected_partner_tax_list,
          tempTaxData,
        ])
      );
      dispatch(onchangeBillrate(bill_rate));
      dispatch(
        onchangeSelectedPartnerList([...selected_partner_list, singleItem])
      );
    }
  }
  return (
    <>
      <div className="selectMultiple">
        <label>Select Partner</label>
        <FormControl style={{ width: "100%" }}>
          <Select
            size="small"
            style={{ border: "1px solid lightgrey", padding: 0 }}
            multiple
            displayEmpty
            value={selectedPartnerList}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              return (
                <p
                  style={{
                    marginLeft: 6,
                    width: "100%",
                    overflowX: "auto",
                    marginTop: -4,
                  }}
                >
                  {selected.join(", ")}
                </p>
              );
            }}
            MenuProps={MenuProps}
            inputProps={{ "aria-label": "Without label" }}
          >
            {temp_partner_data.map((data) => (
              <MenuItem style={{ width: "100%" }} key={data} value={data?.name}>
                <CheckBoxContainer onClick={() => onActionData(data)}>
                  <input
                    ref={inputClick}
                    id="default-checkbox"
                    type="checkbox"
                    name={data?.name}
                    // onChange={() => onActionData(data)}
                    checked={selectedPartnerList.includes(data?.name)}
                    value=""
                  />
                  <label>{data?.name}</label>
                </CheckBoxContainer>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </>
  );
}
