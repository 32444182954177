import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import { Form } from "react-bootstrap";
import CustomButton from "../CommonComponet/Button";
import { Grid } from "@material-ui/core";
import "react-datepicker/dist/react-datepicker.css";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import "react-quill/dist/quill.snow.css";
import HtmlTextEditor from "../CommonComponet/HtmlTextEditor";
import {
  InterviewModeForJob,
  Job_duration_in,
  Job_type,
  SDLC_Process,
  Working_hours,
  domain_list,
  require_education,
} from "../../Utils/mockData";
import {
  ADD_JOB_ERROR,
  ADD_JOB_SUCCESS,
  ERROR_MESSAGE_JOB,
  JOB_CHECK_EXIST_ERROR,
  JOB_DESCRIPTION_ERROR,
  UPDATE_JOB_ERROR,
  UPDATE_JOB_SUCCESS,
} from "../../Utils/alertMessage";
import JobController from "../../controller/JobController";
import RoundLoader from "../CommonComponet/RoundLoader";
import ClientController from "../../controller/ClientController";
import PartnerTaxTermComponentForJob from "../jobs/PartnerTaxTermComponentForJob";
import {
  onchangeSelectedPartnerList,
  onchangeSelectedPartnerTaxList,
  onchangeBillrate,
  changeITempPartnerData,
} from "../../feature/store/ClientReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { JOB_TYPE, TOTAL_EXPERIENCE } from "../../Utils/constant";
import {
  changeBillRate,
  changeTempSelectedFileName,
  removeSelectedFileName,
  updateSelectedFileName,
} from "../../feature/store/JobReduxStore";
import AddAttachment from "../CommonComponet/AddAttachment";
import PartnerDateComponent from "../jobs/PartnerDateComponent";
import { ErrorText, RoundLoaderContainer } from "../../style/common.style";
import { addJobData, updateJobData } from "../../feature/store/ReduxStore";
import {
  CommonString,
  CommonStringForLocalStorage,
  PlaceholderName,
} from "../../Utils/CommonString";
import { changeIsOpenModal } from "../../feature/store/ApplicantStore";
import { useAlert } from "react-alert";
import { ADD_JOBS, UPDATE_JOBS } from "../../Utils/enum";
import CommonBaseModal from "./CommonBaseModal";
import AddPartnerInUpdateJob from "./AddPartnerInUpdateJob";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";

export default function AddJob(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  var jobController = new JobController();
  var clientController = new ClientController();
  const temp_selected_file = useSelector(
    (state) => state.JobReduxStore.temp_selected_file
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const [selectedValueClient, setselectedValueClient] = useState();
  const [clientlList, setClientlList] = useState([]);
  const [zipCode, setZipCode] = useState();
  const [pocName, setPocName] = useState();
  const [departMent, setDepartMent] = useState();
  const [position_title, setposition_title] = useState();
  const [jobLocation, setJobLocation] = useState();
  const [billRate, setBillRate] = useState();
  const [requiredEducation, setRequiredEducation] = useState();
  const [requiredYearOfExperience, setRequiredYearOfExperience] = useState();
  const [preferredSkills, setPreferredSkills] = useState();
  const [openings, setOpenings] = useState();
  const [jobDescription, setJobDescription] = useState();
  const [requiredSkills, setRequiredSkills] = useState();
  const [due_date, setDue_date] = useState(null);
  const [required_sub_details, setRequired_sub_details] = useState();
  const [skill_matrix, setSkill_matrix] = useState();
  const [position_id, setPosition_id] = useState();
  const [max_sub, setMax_sub] = useState();
  const [comment, setComment] = useState();
  const [open_date, setopen_date] = useState();
  const [positionIdError, setpositionIdError] = useState();
  const [taxTerm, SetTaxTerm] = useState();
  const [othertaxTerm, SetOtherTaxTerm] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const selected_partner_tax_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_tax_list
  );
  const client_due_date = useSelector(
    (state) => state.JobReduxStore.client_due_date
  );

  const [ClientLoader, setClientLoader] = useState(false);
  const [jobDurationType, setJobDurationType] = useState(Job_duration_in[0]);
  const [jobDuration, setJobDuration] = useState();
  const [interviewMode, setInterviewMode] = useState();
  const [job_mode, setJob_mode] = useState();
  const [OtherInterviewJobMode, setOtherInterviewMode] = useState();
  const [clientDueDate, setClientDueDate] = useState([]);
  // const [domain, setDomain] = useState();
  // const [sdlcProcess, setSdlcProcess] = useState();
  const [job_type, setJob_type] = useState();
  const [working_hour, setworking_hour] = useState();
  const [OtherWorkingHour, setOtherWorkingHour] = useState();

  useEffect(() => {
    dispatch(changeITempPartnerData([]));
    getClientData();
    dispatch(removeSelectedFileName([]));
    onPrefilledData();
    if (button_name === "Clone Job") {
      jobController.getPartnerData(props.data);
    }
    if (button_name === UPDATE_JOBS) {
      jobController.onGetPartnerInAddJob(
        props.data,
        button_name === UPDATE_JOBS,
        props.data
      );
    } else {
      dispatch(onchangeSelectedPartnerTaxList([]));
      dispatch(onchangeSelectedPartnerList([]));
    }
  }, []);
  async function getJobAttachmentByID() {
    var data = {
      job_id: props.data.job_detail._id,
    };
    var res = await jobController.getAttachmentByID(data);
    dispatch(dispatch(updateSelectedFileName(res.data.data)));
  }
  function onPrefilledData() {
    if (
      (button_name === UPDATE_JOBS || button_name === "Clone Job") &&
      props.data != null
    ) {
      setposition_title(props.data.job_detail.position_title);
      setPosition_id(props.data.job_detail.client_position_id);
      setPocName(props.data.job_detail.poc_name);
      setOpenings(props.data.total_opening);
      setMax_sub(props.data.max_submission);
      setBillRate(props.data.job_detail.bill_rate);
      setRequiredEducation(props.data.job_detail.require_education);
      setRequiredYearOfExperience(
        props.data.job_detail.require_year_experience
      );
      setJobLocation(props.data.job_detail.job_location);
      setZipCode(props.data.job_detail.zip_code);
      setComment(props.data.job_detail.comment);
      setJobDescription(props.data.job_detail.position_description);
      setSkill_matrix(props.data.job_detail.skill_matrix);
      setJobDescription(props.data.job_detail.position_description);
      setSkill_matrix(props.data.job_detail.skill_matrix);
      setDepartMent(props.data.job_detail.department);
      setDue_date(
        props.data.job_detail.due_date === ""
          ? null
          : props.data.job_detail.due_date
      );
      setopen_date(props.data.job_detail.open_date);
      setPreferredSkills(props.data.job_detail.prefred_skills);
      setRequiredSkills(props.data.job_detail.require_skill);
      setJobDuration(props.data.job_detail.project_duration.split(" ")[0]);
      setJobDurationType(props.data.job_detail.project_duration.split(" ")[1]);
      getJobAttachmentByID();
      setInterviewMode(props.data.job_detail.interview_mode);
      setJob_mode(props.data.job_detail.job_mode);
      // setDomain(props.data.job.domain);
      // setSdlcProcess(props.data.job.SDLC_Process);
      setPocName(props.data.job_detail.poc_name);
      setJob_type(props.data.job_detail.job_type);
      setworking_hour(props.data.job_detail.working_hours);
    }
  }

  async function getClientData() {
    setClientLoader(true);
    var res = await clientController.getClients();
    var filteredData = res.filter((data) => data._is_active === 0);
    var temp = [];
    for (let index = 0; index < filteredData.length; index++) {
      const element = filteredData[index];
      var d = {
        name: `${element.name}`,
        id: element._id,
        margin_percentage: element.margin_percentage,
      };
      if (props.data && props.data.job_detail.client_id === element._id) {
        setselectedValueClient(d);
      }
      temp.push(d);
    }
    setClientlList(temp);
    setClientLoader(false);
  }

  function getPartnerData() {
    return JSON.stringify(selected_partner_tax_list);
  }
  function getRecruiterName() {
    if (button_name === UPDATE_JOBS) {
      return "";
    }
    var recruiterName = [];
    selected_partner_tax_list.map((data) =>
      recruiterName.push(data.recruiter_name)
    );
    return recruiterName;
  }
  var user = JSON.parse(localStorage.getItem(CommonStringForLocalStorage.user));
  async function onAddJob() {
    if (!validate()) {
      return;
    }
    if (button_name === "Clone Job") {
      if (await checkExist()) {
        props.handleClose();
        return;
      }
    }
    if (positionIdError !== null && !button_name === UPDATE_JOBS) {
      return;
    }
    setpositionIdError(null);
    var tempTaxTerm = taxTerm === "Others" ? othertaxTerm : taxTerm;
    var workingHour =
      working_hour === "Others" ? OtherWorkingHour : working_hour;
    var data = {
      open_date: open_date ?? Date(),
      due_date: due_date === null ? "" : due_date,
      position_title: position_title ?? "",
      client_id: selectedValueClient.id ?? "",
      department: departMent ?? "",
      partner_id: getPartnerData() ?? "",
      status: button_name === UPDATE_JOBS ? props.data.status : "OPEN",
      total_openings: openings ?? 0,
      max_sub: max_sub ?? 0,
      no_of_sub: 0,
      available_slots: 0,
      client_position_id: position_id ?? "",
      pay_rate: 0,
      bill_rate: billRate ?? 0,
      comment: comment ?? "",
      tax_term: tempTaxTerm ?? "",
      poc_name: pocName ?? "",
      zip_code: zipCode ?? 0,
      position_description: jobDescription
        ? jobDescription.replace("'", "")
        : "",
      job_location: jobLocation ?? "",
      prefred_skills: preferredSkills ?? "",
      require_skill: requiredSkills ?? "",
      require_education: requiredEducation ?? "",
      require_year_experience: requiredYearOfExperience ?? 0,
      skill_matrix: skill_matrix
        ? skill_matrix.toString().replace("'", "")
        : "",
      required_sub_details: required_sub_details ?? "",
      project_duration: jobDuration ? jobDuration + " " + jobDurationType : "",
      recruiter_id: "1",
      recruiter_name: getRecruiterName() ?? "",
      c2c1099: 0,
      w2_with_benifits: 0,
      w2_without_benifits: 0,
      w2_salarized: 0,
      interview_mode:
        interviewMode === "Others"
          ? OtherInterviewJobMode
          : interviewMode ?? "",
      job_type: job_type ?? "",
      job_mode: job_mode ?? "",
      working_hours: workingHour ?? "",
      client_date: JSON.stringify(client_due_date),
      position_added_by_id: user?.user?._id,
      position_updated_by_id: user?.user?._id,
      recruiter_list: selected_partner_tax_list,
    };
    if (button_name === UPDATE_JOBS) {
      var partner_id = JSON.parse(data.partner_id);
      if (partner_id[0].recruter_id === undefined) {
        alert.show(ERROR_MESSAGE_JOB, { type: CommonString.info });
        // setisLoading(false);
        return;
      }
      // let dateToBeCompared = data.due_date;
      // const CompareDate = moment(dateToBeCompared, "DD/MM/YYYY").isBefore(
      //   moment(new Date(), "DD/MM/YYYY"),
      //   "day"
      // );
      const date = JSON.parse(data.client_date)[0];
      var sendData = {
        parent: {
          _id: props.data.job_detail._id,
          ...data,
          position_updated_by: user?.user?._id,
        },
        child: {
          _id: props.data._id,
          recruiter_id: partner_id[0].recruter_id,
          recruiter_name: "",
          total_opening: data.total_openings,
          max_submission: data.max_sub,
          no_of_submission: data.no_of_sub,
          available_slots: data.available_slots,
          _1099: partner_id[0]._1099,
          w2_with_benifits: partner_id[0].w2_with_benifits,
          w2_without_benifit: partner_id[0].w2_without_benifit,
          w2_salarized: partner_id[0].w2_salarized,
          status: data.status,
          open_date: date.open_date,
          due_date: date.due_date,
        },
      };
      dispatch(onChangeIsLoading(true));
      var res = await jobController.updateJob(sendData, temp_selected_file);
      if (res?.data?.status === 200) {
        dispatch(updateJobData(res.data.data[0]));
        dispatch(changeIsOpenModal(false));
        dispatch(onChangeIsLoading(false));
        alert.show(UPDATE_JOB_SUCCESS, { type: CommonString.success });
      } else {
        dispatch(onChangeIsLoading(false));
        alert.show(UPDATE_JOB_ERROR, { type: CommonString.error });
      }
      return;
    }
    dispatch(onChangeIsLoading(true));
    var add_res = await jobController.addJob(data);
    if (add_res?.data?.status === 200) {
      var added_job_id = add_res.data.data[0].job_detail._id;
      var res = await jobController.uploadandSaveAttachment(
        temp_selected_file,
        added_job_id
      );
      dispatch(changeIsOpenModal(false));
      // dispatch(changeIsLoadingInModal(false));
      dispatch(onChangeIsLoading(false));
      dispatch(addJobData(add_res.data.data));
      alert.show(ADD_JOB_SUCCESS, { type: CommonString.success });
    } else {
      // dispatch(changeIsLoadingInModal(false));
      dispatch(onChangeIsLoading(false));
      alert.show(ADD_JOB_ERROR, { type: CommonString.error });
    }
    // props.onSubmitData(data);
  }
  async function checkExist() {
    var sendData = {
      client_position_id: position_id,
    };
    var res = await jobController.checkJobExist(sendData);
    if (
      res.is_exist &&
      (button_name === ADD_JOBS || button_name === "Clone Job")
    ) {
      setpositionIdError("xys");
      return res.is_exist;
    }
    setpositionIdError(null);
    return res.is_exist;
  }
  function validate() {
    if (
      position_title === null ||
      position_title === undefined ||
      position_title === ""
    ) {
      setERROR_NAME("POSITION");
      return false;
    }
    if (
      selectedValueClient === null ||
      selectedValueClient === undefined ||
      selectedValueClient === ""
    ) {
      setERROR_NAME("CLIENT_NAME");
      return false;
    }
    setERROR_NAME("");
    return true;
  }
  function onCalculateBillRate(e) {
    var BillRate = e.replace("$", "").trim();
    dispatch(onchangeBillrate(BillRate));
    dispatch(changeBillRate(BillRate));
    setBillRate(BillRate);
  }

  return (
    <>
      {button_name === "Add Partner" ? (
        <CommonBaseModal
          zIndex={"1300"}
          size={"medium"}
          children={<AddPartnerInUpdateJob data={props.data} />}
        />
      ) : (
        <></>
      )}
      <div style={{ display: props.isView ? "none" : "block" }}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <div>
              {clientlList.length === 0 && ClientLoader ? (
                <div>
                  <SelectTextFields disabled={true} placeholder=" " />
                  <RoundLoaderContainer>
                    <RoundLoader />
                  </RoundLoaderContainer>
                </div>
              ) : (
                <SelectTextFields
                  error={ERROR_NAME === "CLIENT_NAME" ? "fill" : "" > 0}
                  disabled={button_name === UPDATE_JOBS ? true : false}
                  placeholder={PlaceholderName.client_name}
                  value={selectedValueClient}
                  onChange={(e) => {
                    jobController.onGetPartnerInAddJob(
                      e,
                      button_name === UPDATE_JOBS,
                      props.data
                    );
                    setselectedValueClient(e);
                  }}
                  options={clientlList}
                  getOptionLabel={(option) => option.name}
                />
              )}
            </div>
            <FormInput
              value={departMent || ""}
              onChange={(e) => setDepartMent(e.target.value)}
              placeholder="Department"
            />
            <FormInput
              error={ERROR_NAME === "POSITION" ? "fill" : "" > 0}
              value={position_title || ""}
              onChange={(e) => {
                setposition_title(e.target.value);
                setERROR_NAME("");
              }}
              placeholder="Position Title *"
            />
            <FormInput
              value={position_id || ""}
              onChange={(e) => setPosition_id(e.target.value)}
              placeholder="Client Position ID"
              onBlur={() => checkExist()}
            />
            {positionIdError != null ? (
              <ErrorText>{JOB_CHECK_EXIST_ERROR}</ErrorText>
            ) : (
              <div />
            )}
            <FormInput
              value={jobLocation || ""}
              onChange={(e) => setJobLocation(e.target.value)}
              placeholder="Job Location"
            />
            <FormInput
              value={zipCode || ""}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="Postal Code"
            />
            <Grid container spacing={1}>
              <Grid item md={6} xs={6}>
                <FormInput
                  value={jobDuration || ""}
                  onChange={(e) => setJobDuration(e.target.value)}
                  placeholder="Job Duration"
                />
                <FormInput
                  value={openings || ""}
                  onChange={(e) => setOpenings(e.target.value)}
                  placeholder="Openings Total"
                />
                <SelectTextFields
                  placeholder={PlaceholderName.interview_mode}
                  value={interviewMode || ""}
                  onChange={(e) => setInterviewMode(e)}
                  options={InterviewModeForJob}
                  getOptionLabel={(option) => option}
                />
                {interviewMode === "Others" ? (
                  <FormInput
                    onChange={(e) => setOtherInterviewMode(e.target.value)}
                    value={OtherInterviewJobMode || ""}
                    placeholder={PlaceholderName.interview_mode}
                  />
                ) : (
                  <div />
                )}
                {/* <SelectTextFields
                  placeholder="Domain"
                  value={domain || ""}
                  onChange={(e, value) => setDomain(value)}
                  options={domain_list}
                  getOptionLabel={(option) => option}
                /> */}
                <SelectTextFields
                  placeholder={PlaceholderName.job_type}
                  value={job_type || ""}
                  onChange={(e) => setJob_type(e)}
                  options={Job_type}
                  getOptionLabel={(option) => option}
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <SelectTextFields
                  placeholder={PlaceholderName.job_duration_type}
                  value={jobDurationType}
                  onChange={(e) => setJobDurationType(e)}
                  options={Job_duration_in}
                  getOptionLabel={(option) => option}
                />
                <FormInput
                  value={max_sub || ""}
                  onChange={(e) => setMax_sub(e.target.value)}
                  placeholder="Max Submission"
                />
                <SelectTextFields
                  placeholder={PlaceholderName.job_mode}
                  value={job_mode || ""}
                  onChange={(e) => setJob_mode(e)}
                  options={JOB_TYPE}
                  getOptionLabel={(option) => option}
                />
                {/* <SelectTextFields
                  placeholder="SDLC Process"
                  value={sdlcProcess || ""}
                  onChange={(e, value) => setSdlcProcess(value)}
                  options={SDLC_Process}
                  getOptionLabel={(option) => option}
                /> */}
                <SelectTextFields
                  placeholder={PlaceholderName.working_hours}
                  value={working_hour || ""}
                  onChange={(e) => setworking_hour(e)}
                  options={Working_hours}
                  getOptionLabel={(option) => option}
                />
                {working_hour === "Others" ? (
                  <FormInput
                    onChange={(e) => setOtherWorkingHour(e.target.value)}
                    value={OtherWorkingHour || ""}
                    placeholder={PlaceholderName.working_hours}
                  />
                ) : (
                  <div />
                )}
              </Grid>
            </Grid>
            <FormInput
              value={pocName || ""}
              onChange={(e) => setPocName(e.target.value)}
              placeholder="Contract Manger/ POC Name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <FormInput
                  value={billRate || ""}
                  onChange={(e) => onCalculateBillRate(e.target.value)}
                  placeholder="Bill Rate / Hour"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectTextFields
                  placeholder={PlaceholderName.required_education}
                  value={requiredEducation || ""}
                  onChange={(e) => setRequiredEducation(e)}
                  options={require_education}
                  getOptionLabel={(option) => option}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectTextFields
                  placeholder={PlaceholderName.years_of_experience}
                  value={requiredYearOfExperience || ""}
                  onChange={(e) => setRequiredYearOfExperience(e)}
                  options={TOTAL_EXPERIENCE}
                  getOptionLabel={(option) => option}
                />
              </Grid>
            </Grid>
            <PartnerTaxTermComponentForJob
              selectedValueClient={selectedValueClient}
              data={props.data}
              isUpdate={button_name === UPDATE_JOBS}
            />
            {selected_partner_tax_list.map((data) => (
              <PartnerDateComponent
                data={data}
                jobData={props.data !== null ? props.data.job_detail : ""}
                childJob={props.data !== null ? props.data : ""}
                isUpdate={button_name === UPDATE_JOBS}
                clientDueDate={clientDueDate}
              />
            ))}
            <Form.Label>Job Description</Form.Label>
            <HtmlTextEditor
              value={jobDescription || ""}
              onChange={(e) => setJobDescription(e)}
            />
            {ERROR_NAME === "JOB_DESC" ? (
              <ErrorText>{JOB_DESCRIPTION_ERROR}</ErrorText>
            ) : (
              <div />
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Form.Label>Skill Matrix</Form.Label>
            <HtmlTextEditor
              value={skill_matrix || ""}
              onChange={(e) => setSkill_matrix(e)}
            />
            <FormInput
              multiline
              value={comment || ""}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Comment"
            />
            <AddAttachment
              onRefresh={() => getJobAttachmentByID()}
              isUpdate={button_name === UPDATE_JOBS}
              onselect={(e) => dispatch(changeTempSelectedFileName(e))}
            />
            <CustomButton
              onClick={onAddJob}
              isLoading={props.isLoading}
              name={button_name === UPDATE_JOBS ? "Update" : "Add"}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
