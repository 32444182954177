import React, { useEffect, useState } from "react";
import { ErrorText } from "../../style/common.style";
import moment from "moment";

export default function CommonDatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (props.value !== undefined) {
      setSelectedDate(moment(props.value).format("YYYY-MM-DD"));
    }
  }, [props.value]);

  return (
    <div>
      <label for={props.name}>{props.name}</label>
      <input
        {...props}
        onChange={(e) => props.onChange(e.target.value)}
        onClick={(e) => e.currentTarget.showPicker()}
        type="date"
        name={props.name}
        value={selectedDate !== null ? selectedDate : props.value}
      />
      {props.error ? (
        <ErrorText>Please add {props.placeholder} </ErrorText>
      ) : (
        <></>
      )}
    </div>
  );
}
