import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../feature/store/ReduxStore";
import JobReduxStore from "../feature/store/JobReduxStore";
import SubmissionReduxStore from "../feature/store/SubmissionReduxStore";
import ClientReduxStore from "../feature/store/ClientReduxStore";
import ApplicantStore from "../feature/store/ApplicantStore";
import FirebaseStoreRedux from "../feature/store/FirebaseStoreRedux";
import LevelStoreRedux from "../feature/store/LevelReduxStore";
import ReportStoreRedux from "../feature/store/ReportStore";

export default configureStore({
  reducer: {
    counter: counterReducer,
    JobReduxStore: JobReduxStore,
    SubmissionReduxStore: SubmissionReduxStore,
    ClientReduxStore: ClientReduxStore,
    ApplicantStore: ApplicantStore,
    FirebaseStoreRedux: FirebaseStoreRedux,
    LevelStoreRedux: LevelStoreRedux,
    ReportStoreRedux: ReportStoreRedux,
  },
});
