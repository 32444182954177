import moment from "moment";
import React from "react";
import {
  IntrviewShowDetail,
  SubmissionStatusContainer,
} from "../../../style/common.style";
import { CommonString } from "../../../Utils/CommonString";
import { DATE_FORMATE, SUBMISSION_STATUS } from "../../../Utils/constant";
import SubmissionStatus from "./SubmissionStatus";

export default function InterviewDetailView(props) {
  function getStatus() {
    if (props.data.status === SUBMISSION_STATUS.INTERVIEW) {
      return (
        <SubmissionStatusContainer>
          <IntrviewShowDetail onClick={props?.onClickInterviewDetail}>
            {CommonString.interview_details +
              " (" +
              moment(props?.data?.interviewData?.data?.date).format(
                DATE_FORMATE
              ) +
              ")"}
          </IntrviewShowDetail>
        </SubmissionStatusContainer>
      );
    } else if (props?.data?.status === SUBMISSION_STATUS.SUBMISSION) {
      return (
        <SubmissionStatus
          data={props?.data}
          onClick={props.onClickSubmissionTracker}
        />
      );
    }
    return "";
  }
  return getStatus();
}
