import React, { useEffect, useState } from "react";
import StatusTimelineBlock from "./StatusTimelineBlock";
import { ButtonContainer, FlexContainer } from "../../style/status.style";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { OutlinedBtnForAddPartner } from "../../style/common.style";
const status = [
  "IN TOUCH",
  "LOCKED",
  "REVIEW",
  "PLEASE SUBMIT",
  "SUBMISSION",
  "HOLD INTERNALLY",
  "REJECTED INTERNALLY",
  "REJECTED BY CLIENT",
  "INTERVIEW",
  "INTERVIEW NO SHOW",
  "OFFER",
  "DECLINED/BD BY CLIENT",
  "DECLINED/BD BY CONSULTANT",
  "PLACEMENT",
  "TERMINATED",
  "RESIGNED",
  "PROJECT END",
];

const accessStatus = [
  "IN TOUCH",
  "LOCKED",
  "REVIEW",
  "SUBMISSION",
  "INTERVIEW",
];

export default function StatusTimeline(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setActiveIndex(status.indexOf(props?.status?.toUpperCase()));
  }, []);

  function getSkippedStatus(status) {
    if (!onCheckLevelAccessibility()) {
      if (status == "IN TOUCH") {
        return [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
      if (status == "LOCKED") {
        return [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
      if (status == "REVIEW") {
        return [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
      if (status == "PLEASE SUBMIT") {
        return [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
      if (status == "SUBMISSION") {
        return [3, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
      if (status == "INTERVIEW") {
        return [3, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17];
      }
    }
    if (status == "REJECTED INTERNALLY") {
      return [3, 4, 5, 7];
    }
    if (status == "HOLD INTERNALLY") {
      return [3, 4, 6, 7];
    }
    if (status == "REJECTED BY CLIENT") {
      return [5, 6];
    }
    if (status == "INTERVIEW") {
      return [5, 6, 7];
    }
    if (status == "OFFER" || status == "DECLINED/BD BY CLIENT") {
      return [5, 6, 7, 9, 11, 12];
    }
    if (status == "DECLINED/BD BY CONSULTANT") {
      return [5, 6, 7, 9, 11];
    }
    if (status == "SUBMISSION") {
      return [5, 6];
    }
    if (status == "PLACEMENT") {
      return [5, 6, 7, 9, 11, 12];
    }
    if (status == "PROJECT END") {
      return [5, 6, 7, 9, 11, 12, 14, 15];
    }
    return [];
  }

  function getArray() {
    return status;
  }
  function onClickStatus(ele, index) {
    if (onCheckLevelAccessibility()) {
      props.onClick(ele);
      return;
    }
    if (index < activeIndex) {
      return;
    }
    let status = ele;
    if (
      !onCheckLevelAccessibility() &&
      !accessStatus.includes(ele.toUpperCase())
    ) {
      return;
    }
    props.onClick(status);
  }
  return (
    <FlexContainer
      margin="0 20px 40px 20px"
      width="100vw"
      flexWrap="wrap"
      gap="70px 0"
      jc="flex-start"
    >
      {[...getArray()].map((ele, index) => {
        var is_skipped = getSkippedStatus(
          props?.status?.toUpperCase()
        ).includes(index);
        return (
          <StatusTimelineBlock
            line={index === status.length - 1 ? "N" : "R"}
            title={ele}
            isActive={index === activeIndex}
            isCompleted={index < activeIndex && !is_skipped}
            setTimelineStatus={() => onClickStatus(ele, index)}
            isSkip={is_skipped}
          />
        );
      })}
      {/* <ButtonContainer visible={onCheckLevelAccessibility()}>
        <OutlinedBtnForAddPartner onClick={() => console.log()}>
          Change Partner
        </OutlinedBtnForAddPartner>
      </ButtonContainer> */}
    </FlexContainer>
  );
}
