import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import HomeScreen from "./screen/HomeScreen/HomeScreen";
import LoginScreen from "./screen/AuthValidation/LoginScreen/LoginScreen";
import { CommonStringForLocalStorage } from "./Utils/CommonString";
import { useDispatch } from "react-redux";
import {
  addInChatList,
  changeChatList,
} from "./feature/store/SubmissionReduxStore";
import { io } from "socket.io-client";
export default function Routes() {
  const dispatch = useDispatch();
  useEffect(() => {
    const socket = io("https://api.prexelite.com/"); // Replace with your server URL
    socket.on("get", (data) => {
      dispatch(changeChatList(data));
    });
    socket.on("addsubmitionV2", (data) => {
      dispatch(addInChatList(data));
    });
    socket.on("changestatus", (data) => {
      dispatch(addInChatList(data));
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  function getRedirect() {
    var tk = localStorage.getItem(CommonStringForLocalStorage.token);
    if (tk == null) {
      return <Redirect to="/login" />;
    }
    return <Redirect to="/dashboard" />;
  }
  return (
    <Switch>
      <Route exact path="/" render={() => getRedirect()} />
      <Route exact path="/login" component={LoginScreen} />
      <Route exact path="/dashboard" component={HomeScreen} />
    </Switch>
  );
}
