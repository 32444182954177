import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { Button, Divider } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { Icon } from "@iconify/react";
import { CommonString } from "../Utils/CommonString";

const TextWithIconContainer = styled.div`
  display: ${(props) => (props.data === "" ? "none" : "flex")};
  align-items: center;
`;
const CheckBoxContainer = styled.div`
  display: flex;
  /* align-items: center; */
  margin-bottom: 4px;
  input {
    margin-bottom: 0 !important;
    margin-right: 5px;
    width: auto;
  }
  label {
    cursor: pointer;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
`;
const SwitchWithTitle = styled.div`
  /* padding-top: 7px !important; */
  padding-bottom: 7px !important;
  margin-top: 7px;
  display: flex;
  align-items: center;
`;
const TextInTextIcon = styled.span`
  margin-left: 7px;
  font-size: 11px;
`;

const NoDataContainer = styled.div`
  width: -webkit-fill-available;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
`;

const SubTitle = styled.h6`
  padding: 5px;
  padding-top: 10px;
  color: ${CommonColor.primary_color};
  font-weight: bold;
`;

const SubTitleSpan = styled.span`
  color: ${CommonColor.secondary_color};
`;

const GroupButtonContainer = styled.span`
  display: flex;
  margin-top: 10px;
`;

const GroupOutlinedButton = styled.span`
  margin-left: 10px;
`;

const OutlinedBtnForGroup = styled(Button)`
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "50% !important")};
  padding: 3px 15px;
  font-size: 10px !important;
  margin-left: 5px !important;
  color: ${CommonColor.primary_color}!important;
  background-color: ${CommonColor.white}!important;
  /* margin-top: ${(props) =>
    props.margintop ? props.margintop : 0} !important; */
`;

const SolidBtnForGroup = styled(Button)`
  white-space: nowrap;
  width: ${(props) => (props.width ? props.width : "50% !important")};
  padding: 3px 15px;
  background-color: ${CommonColor.primary_color}!important;
  color: ${CommonColor.white}!important;
  margin-right: 5px !important;
  &:hover {
    background: ${CommonColor.button_hover}!important;
  }
`;

const SolidBtn = styled(Button)`
  width: auto;
  font-size: 10px !important;
  padding: 7px 25px !important;
  background-color: ${CommonColor.primary_color}!important;
  color: ${CommonColor.white}!important;
  margin-right: 5px !important;
  border-radius: 5px !important;
  border: 1px solid ${CommonColor.primary_color} !important;
  margin-top: ${(props) => (props.noMarginTop ? 0 : "10px")} !important;
  // line-height: 0 !important;
  white-space: nowrap;
  /* height: fit-content; */
  &:hover {
    background: ${CommonColor.button_hover}!important;
  }
`;

const OutlinedBtn = styled(SolidBtn)`
  width: auto;
  padding: 5px 15px !important;
  font-size: 10px !important;
  border: 1px solid ${CommonColor.primary_color} !important;
  background-color: ${CommonColor.transparent} !important;
  color: ${CommonColor.primary_color} !important;
  margin: 5px !important;
  line-height: 0 !important;
  height: fit-content;
  padding: ${(props) =>
    props.customizeButton ? "15px !important" : "inherit"};
  margin-top: ${(props) =>
    props.customizeButton ? "10px !important" : "inherit"};
  &:hover {
    background: ${CommonColor.transparent}!important;
  }
  @media ${CommonString.mobile} {
    padding: 3px 5px !important;
  }
`;
const OutlinedBtnForAddPartner = styled(SolidBtn)`
  border: 1px solid ${CommonColor.secondary_color} !important;
  color: ${CommonColor.secondary_color} !important;
  font-weight: bolder;
  background: ${CommonColor.secondary_bg}!important;
  &:hover {
    background: ${CommonColor.secondary_color}!important;
    color: ${CommonColor.white} !important;
  }
  margin-top: 0 !important;
  height: fit-content;
  // margin: 5px !important;
  // line-height: 0 !important;
  @media ${CommonString.mobile} {
    padding: 3px 5px !important;
  }
`;
const ModalTitle = styled.h4`
  color: ${CommonColor.primary_color};
  font-weight: bold;
  margin: 0 24px;
  @media ${CommonString.mobile} {
    font-size: 17px;
    margin-bottom: ${(props) => (props.margin ? "12px" : "0")} !important;
  }
`;
const ModalTitleSpan = styled.span`
  /* color: ${CommonColor.secondary_color}; */
`;
const ModalIconContainer = styled.div`
  display: flex;
  margin: 0 24px;
`;
const FormTitleContainer = styled.div`
  margin-bottom: 10px;
  margin: 0 -24px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid lightgray;
  overflow: hidden;
`;
const FormTitleContainerForConfirmation = styled.div`
  margin-bottom: 20px;
  margin: 0 -24px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid lightgray;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalDataContainer = styled.div`
  //  overflow: auto;
  margin-top: 20px;
`;
const DeleteConfirmationFormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin: 0 -22px !important;
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  border-bottom: 1px solid lightgray;
  overflow: hidden;
  align-items: center;
`;
const UserIconWithName = styled.span`
  display: flex;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  @media ${CommonString.mobile} {
    display: none;
  }
`;
const UserName = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
  @media ${CommonString.mobile} {
    display: none;
  }
`;

const LockButtonContainer = styled.div`
  position: absolute;
  bottom: 6%;
  right: 2%;
`;

const CopyTextContainer = styled.p`
  padding: 2px 10px;
  margin-left: -10px;
  width: fit-content;
  background: red;
  &:hover {
    text-decoration: underline;
  }
`;

const FormField = styled.div`
  margin-top: 10px !important;
  // margin-bottom:10px !important;
  width: 100%;
  border-color: ${CommonColor.light_grey};
`;

const TextInProfile = styled.h5`
  color: ${CommonColor.primary_color};
  /* margin-bottom: 5px; */
  font-weight: 900;
  letter-spacing: 0.4px;
`;

const TextInProfileCard = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  /* margin: 10px 0px; */
  // border-radius: 10px;
  // padding: 30px 5px;
  // text-align: center;
  // width: 80px;
  // margin: 15px 10px;
  // box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07),
  //   0px 5.4px 13px rgba(0, 0, 0, 0.035);
  // font-weight: bold;
`;

const TextInProfileSpan = styled.h5`
  color: ${CommonColor.secondary_color};
  padding: 0 8px !important;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: ${CommonColor.secondary_bg};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  font-weight: 900;
`;

const CustomDivider = styled(Divider)`
  font-weight: 700;
  letter-spacing: 1.2px;
  border-radius: 50px;
  text-align: center;
  margin-bottom: 2px !important;
  width: auto;
  span {
    padding: 0px 0px !important;
  }
`;

const ViewDetailContainer = styled.div`
  border: 0.5px solid ${CommonColor.border};
  border-color: ${CommonColor.border};
  border-radius: 5px;
`;

const IntrviewShowDetail = styled.p`
  margin-right: 5px;
  color: #ff6600;
  clear: both;
  display: inline-block;
  border-bottom: 0.2px solid transparent;
  text-align: center;
  white-space: nowrap;
  &:hover {
    border-bottom: 0.2px solid #ff6600;
  }
`;

const RadioGroupCustom = styled(RadioGroup)`
  display: flex !important;
  align-items: center;
  flex-direction: row !important;
`;

const RadioGroupTitle = styled.p`
  color: ${CommonColor.primary_color};
  font-weight: bold;
`;

const ApplicantViewContainer = styled.div`
  border: 0.5px solid ${CommonColor.border};
  border-radius: 5px;
  height: ${(props) => (props.isApplicantView ? "60vh" : "84vh")};
  overflow: hidden;
`;

const TitleKey = styled.span`
  font-family: "GlacialIndifferenceRegular", sans-serif;
  font-weight: bold;
  color: grey;
  margin: 5px 7px;
  color: ${(props) => (props.color ? props.color : CommonColor.grey)};
`;

const TitleValue = styled.span`
  word-break: break-all;
  text-transform: ${(props) => (props.isemail ? "lowercase" : "capitalize")};
  font-weight: bold;
  // font-size: ${(props) => (props.color ? "14px" : "inherit")};
  color: ${(props) => (props.color ? props.color : CommonColor.primary_color)};
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  p {
    margin: 0 5px;
  }
  table {
    margin: 0 5px;
    tr:first-child {
      background: ${(props) =>
        props.skillMatrix
          ? CommonColor.table_heading_color
          : CommonColor.transparent};
    }
  }
  td {
    border: ${(props) =>
      props.skillMatrix ? "1px solid" + CommonColor.light_grey : "none"};
  }
`;

const TitleHR = styled.hr`
  color: ${CommonColor.border};
  margin: 0 !important;
`;

const ReadMoreReadLessText = styled.span`
  color: ${CommonColor.secondary_color};
  font-size: 12px;
  margin-left: 0px;
  transition: 2s;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const StatusBtn = styled(Button)`
  width: auto;
  padding: 2px 15px !important;
  margin: 10px !important;
  font-weight: 600 !important;
`;
const StatusButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
`;
const LottieContainer = styled.div`
  width: 200px;
`;
const StatusText = styled.h4`
  font-weight: bold;
  color: ${CommonColor.grey};
  margin-bottom: 15px !important;
`;

const IframeContainer = styled.iframe`
  width: 100%;
  height: inherit;
`;

const FilterSpan = styled.span`
  font-size: ${(props) => (props.isVisible ? "inherit" : "9px")} !important;
  margin-right: 5px;
`;

const FilterContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const FlexContainer = styled.div`
  display: flex !important;
  align-items: center;
  place-content: ${(props) => props.align ?? "flex-end"} !important;
`;

const SubmissionStatusContainer = styled.div`
  text-align: left;
  // width: 100%;
  display: flex !important;
  cursor: pointer;
`;

const DashBoardIcon = styled.img`
  width: 100px;
  position: absolute;
  right: -20%;
  top: -66%;
`;
const LogoutButtonContainer = styled.p`
  padding: 0;
  padding-bottom: 10px;
  cursor: pointer;
  text-align: center;
  &:hover {
    background-color: rgba(37, 63, 80, 0.15);
  }
`;

const CustomForm = styled.div`
  background-color: transparent;
  border-radius: 10px;
  padding: 25px;
  width: 400px;
  /* margin-top: 30px; */
  box-shadow: 0px 25px 86px rgba(0, 0, 0, 0.15);
  button {
    text-align: center;
  }
  @media ${CommonString.mobile} {
    width: 320px;
  }
`;

const FaqContent = styled.div`
  margin-left: 35px;
  color: #3c3c43;
`;

const FaqNumber = styled.span`
  color: #9d9da1;
  margin-right: 10px;
  font-weight: bold;
`;

const FaqTitle = styled.span`
  font-weight: bold;
`;

const CallTrackerTextArea = styled.textarea`
  max-height: 100px;
  text-align: left;
  margin-top: 7px;
  border: 1px solid lightGrey;
  width: 100%;
`;

const CallTrackerFormUiContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ShowPassText = styled.p`
  color: #253f5090;
  font-size: 11px;
  padding: 0;
  margin: 10px 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const PlaceholderAnimation = styled.div`
  margin: 0;
  max-width: 100%;
  height: 10px;
  width: 500vh;
  background-color: #eee;
  margin-top: 3px !important;
`;

const AnimatedBackground = styled.div`
  animation-duration: 1.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 10px;
  position: relative;
`;

const HideScroll = styled.div`
  height: 84vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ViewResumeTExtContainer = styled.div`
  padding: 10px 20px;
  text-align: left;
  cursor: pointer;
`;

const ViewResumeText = styled.p`
  text-decoration: underline;
  color: ${CommonColor.secondary_color};
`;

const ProfileUrl = styled.img`
  object-fit: fill;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 20px;
  @media ${CommonString.mobile} {
    width: 150px;
    height: ${(props) => (props.profileUrl === "" ? "0px" : "150px")};
  }
`;
const ProfileContainer = styled.div`
  padding-right: 20px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${CommonString.mobile} {
    padding-right: 0px;
  }
`;
const ProfileUrlInUserDropdown = styled.img`
  width: 100%;
  box-shadow: 0 4px 8px 0 rgb(83 82 82 / 10%), 0 6px 20px 0 rgba(0, 0, 0, 0);
`;
const POCTitle = styled.h5`
  margin: 10px 0;
  margin-bottom: 5px !important;
  color: ${CommonColor.primary_color};
  display: ${(props) => (props.isDisplayNone ? "none" : "table")};
`;
const ApplicantSearchContainer = styled.div`
  width: -webkit-fill-available;
`;
const AttachmentContainer = styled.div`
  display: ${(props) => (props.isUpdate ? "none" : "block")};
  margin-top: 7px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed ${CommonColor.grey};
  border-radius: 15px;
  margin-bottom: 20px;
  transition: 2s;
  cursor: pointer;
  margin-top: 10px;
  input {
    display: none;
  }
`;
const AttachmentButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: 2s;
  img {
    width: 50px;
    margin-bottom: 10px;
  }
`;

const AttachmentDownloadContainer = styled.a`
  color: ${CommonColor.secondary_color} !important;
  border: 1px solid ${CommonColor.secondary_color};
  border-radius: 5px;
  padding: 7px 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  /* margin-top: -5px; */
  margin-bottom: ${(props) => (props.isVisible ? "inherit" : "15px")};
  height: fit-content;
  margin-right: 10px;
  svg {
    margin-right: 5px;
    font-size: 16px;
  }
`;
const DownloadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
`;
const FileName = styled.span`
  font-size: 11px;
  font-weight: 500;
`;

const FileNameContainer = styled.div`
  border: 1px solid ${CommonColor.border};
  border-radius: 5px;
  margin: 5px;
  padding: 5px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 10%);
  &:hover {
    // background: ${CommonColor.primary_bg};
  }
`;
const FileNameCloseIcon = styled(Icon)`
  font-size: 28px;
  padding: 5px;
  border-radius: 50%;
  color: ${CommonColor.border};
  &:hover {
    color: ${CommonColor.primary_color};
    background: ${CommonColor.primary_bg};
  }
`;
const MultiFileContainer = styled.div`
  // display: flex;
  // overflow: auto;
  transition: 1s;
`;
const MultiFileView = styled.a`
  // display: flex;
  // overflow: auto;
  transition: 1s;
`;
const MenuContainer = styled.div`
  background: ${CommonColor.primary_color};
  width: 400px;
  height: 100vh;
  position: absolute;
  right: ${(props) => (props.isOpen ? "0" : "-400px")};
  top: 0;
  color: ${CommonColor.white};
  z-index: 100;
  position: absolute;
  transition: 0.8s;
  padding: 10px;
  padding-top: 40px;
  overflow: hidden;
  a {
    // opacity:${(props) => (props.isOpen ? "1" : "0")};
    transition: 0.1s;
  }
`;

const MultiFileContainerText = styled.a`
  cursor: pointer;
  color: ${CommonColor.white}!important;
  display: flex;
  align-items: center;
`;

const MultiFileContainerIcon = styled(Icon)`
  font-size: ${(props) => (props.isDownload ? "18px" : "34px")};
  color: ${(props) =>
    props.isDownload ? CommonColor.secondary_color : "inherit"};
  margin-right: ${(props) => (props.isDownload ? "0" : "15px")};
  margin-left: ${(props) => (props.isDownload ? "10px" : "0px")};
`;

const MultiFileContainerTextContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin: 10px 0;
  &:hover {
    background: ${CommonColor.primary_hover_color};
    border-radius: 8px;
  }
`;

const MultiFileContainerCloseIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 32px;
  padding: 5px;
  border-radius: 50%;
  &:hover {
    background: ${CommonColor.primary_hover_color};
  }
`;
const MultiFileContainerIconContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  span {
    word-break: break-all;
  }
`;
const Div = styled.div``;
const HR = styled.hr`
  margin: 5px 0 !important;
  display: ${(props) => (props.data ? "none" : "block")};
`;
const GroupBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -5px;
  margin-top: 10px;
  button {
    width: -webkit-fill-available;
    margin: 0 5px !important;
    border: 1px solid ${CommonColor.primary_color};
    line-height: 0 !important;
  }
  :first-child {
    margin-right: 5px !important;
  }
`;
const ErrorText = styled.p`
  color: ${CommonColor.secondary_color};
`;
const RoundLoaderContainer = styled.div`
  position: absolute;
`;
const FullScreenLoaderContainer = styled.div`
  background: ${CommonColor.white};
  padding: 10px;
  border-radius: 15px;
  text-align: center;
  padding-bottom: 30px;
  background-color: ${CommonColor.light_grey};
`;
const FullScreenLoaderContent = styled.h6`
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 0;
  color: ${CommonColor.primary_color};
`;
const FullScreenLoaderMainContainer = styled.div`
  background: #0000006e;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;
const ReadMoreReadLessContent = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.onClickReadmore ? "1" : "")};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
const ConfirmationFormTitle = styled.p`
  font-size: 14px !important;
  max-width: 300px;
  span {
    text-transform: capitalize;
    color: ${CommonColor.secondary_color} !important;
  }
`;
const DateContainer = styled.div`
  /* margin-top: 10px; */
  display: ${(props) => (props.noDisplay ? "none" : "inherit")};
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    position: absolute;
    right: 10px;
    display: ${(props) => (props.searchText.length > 1 ? "block" : "none")};
    z-index: 3;
    top: 3vh;
    width: 35px;
    height: 35px;
    color: ${CommonColor.white};
    background-color: ${CommonColor.secondary_color};
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;
  }
`;
const FlexContainerForRadioButton = styled(RadioGroup)`
  flex-wrap: wrap;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  span:nth-last-child(1) {
    font-size: 12px;
    margin-right: 20px;
  }
  button {
    margin: 0 !important;
  }
`;
const LoaderContainer = styled.div`
  position: absolute !important;
  bottom: 3vh;
  z-index: 9;
  left: ${(props) => (props.is_applicant ? "15vw" : "50vw")};
`;
const ButtonWithoutBackground = styled(Button)`
  border: none !important;
  width: auto !important;
  font-size: 10px !important;
  // padding: 7px 25px !important;
  color: ${CommonColor.primary_color}!important;
  margin-right: 5px !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  line-height: 0 !important;
  &:hover {
    background-color: ${CommonColor.primary_bg}!important;
  }
  span {
    // margin-right: 5px;
  }
  svg {
    width: 12px;
    height: 12px;
    margin: 0 !important;
  }
`;
const GraphContainer = styled.div`
  background-color: ${CommonColor.white};
  padding: 20px;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
  border-radius: 5px;
  height: 100%;
`;
const ReportContainer = styled.div`
  padding: 10px;
  height: 90vh;
  overflow: auto;
`;
const CardContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const CardForReport = styled.div`
  height: 100px;
  width: 150px;
  background-color: ${CommonColor.white};
  padding: 10px;
  box-shadow: 0 0.125rem 0.625rem 0 rgba(76, 78, 100, 0.22);
  border-radius: 5px;
`;
const FilterButtonContainer = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  button {
    border: 1px solid ${CommonColor.primary_color};
    line-height: 1.75 !important;
    padding: 7px 25px !important;
  }
`;
const CustomChip = styled.div`
  border-radius: 5px;
  padding: 5px 10px;
  background-color: ${CommonColor.table_header_color};
  color: ${CommonColor.primary_color};
  font-size: 11px;
  font-weight: 700;
  width: fit-content;
  white-space: nowrap;
  display: flex;
  align-items: center;
`;
const CustomChipIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  padding: 2px;
  &:hover {
    background-color: ${CommonColor.light_text_color};
    border-radius: 50%;
  }
`;
const Title = styled.h6`
  font-size: 14px;
  font-weight: 700;
  color: ${CommonColor.primary_color};
  span {
    color: ${CommonColor.secondary_color};
  }
`;
const TableLoaderContainer = styled.div`
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  /* left: 100px; */
  top: 0;
  left: 0;
  background-color: ${CommonColor.background_black_opacity_color};
`;
const TableNoDataContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
`;
const TextArea = styled.textarea`
  border-color: ${CommonColor.light_grey};
  padding: 8.5px 14px;
  /* margin-top: 10px; */
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  &:hover {
    border-color: ${CommonColor.grey};
  }
`;
const FormLabel = styled.label`
  font-size: 11px;
  margin-top: 10px !important;
  white-space: nowrap;
  /* text- ; */
`;
const FieldFilterIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 5px 10px;
`;
const FilterIconInTable = styled(Icon)`
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin-left: 5px;
  color: ${(props) =>
    props.is_active ? CommonColor.secondary_color : CommonColor.white};
`;
const FlexContainerForFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  span {
    font-size: 8px;
  }
`;
const MenuItemForFilter = styled.div`
  background-color: ${(props) =>
    props.activeStatus ? CommonColor.primary_bg : CommonColor.white};
  padding: 2px 10px;
  cursor: pointer;
  &:hover {
    background-color: ${CommonColor.primary_bg};
  }
`;
const ClearFilterText = styled.p`
  cursor: pointer;
  color: ${CommonColor.blue};
  &:hover {
    text-decoration: underline;
    color: ${CommonColor.primary_color};
  }
`;
const CopyTextMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* cursor: pointer; */
  /* color: ${CommonColor.blue}; */
  position: relative;
  svg {
    display: none !important;
    background-color: ${CommonColor.light_text};
    border-radius: 18%;
    padding: 3px;
    width: 15px;
    height: 15px;
  }
  &:hover svg {
    position: absolute;
    right: -5px;
    display: block !important;
  }
`;
export {
  CopyTextMainContainer,
  ClearFilterText,
  MenuItemForFilter,
  FlexContainerForFilter,
  FilterIconInTable,
  FieldFilterIcon,
  FormLabel,
  TextArea,
  TableNoDataContainer,
  TableLoaderContainer,
  Title,
  CustomChip,
  CustomChipIcon,
  FullScreenLoaderMainContainer,
  FilterButtonContainer,
  OutlinedBtnForAddPartner,
  CardForReport,
  CardContainer,
  ReportContainer,
  GraphContainer,
  ButtonWithoutBackground,
  LoaderContainer,
  FlexContainerForRadioButton,
  SearchContainer,
  DateContainer,
  ConfirmationFormTitle,
  ReadMoreReadLessContent,
  FullScreenLoaderContainer,
  RoundLoaderContainer,
  ErrorText,
  FormTitleContainerForConfirmation,
  GroupBtnContainer,
  StatusText,
  StatusBtn,
  DeleteConfirmationFormTitle,
  HR,
  Div,
  MultiFileView,
  MultiFileContainerIconContainer,
  MultiFileContainerIcon,
  MultiFileContainerCloseIcon,
  MultiFileContainerTextContainer,
  MultiFileContainerText,
  MenuContainer,
  MultiFileContainer,
  FileNameCloseIcon,
  FileNameContainer,
  FileName,
  CheckBoxContainer,
  DownloadContainer,
  AttachmentDownloadContainer,
  AttachmentButtonContainer,
  AttachmentContainer,
  ApplicantSearchContainer,
  POCTitle,
  ProfileUrlInUserDropdown,
  ProfileContainer,
  ProfileUrl,
  ViewResumeText,
  ViewResumeTExtContainer,
  HideScroll,
  AnimatedBackground,
  PlaceholderAnimation,
  ShowPassText,
  CallTrackerFormUiContainer,
  CallTrackerTextArea,
  FaqTitle,
  FaqNumber,
  FaqContent,
  CustomForm,
  LogoutButtonContainer,
  DashBoardIcon,
  SubmissionStatusContainer,
  FlexContainer,
  FilterContainer,
  FilterSpan,
  IframeContainer,
  StatusButtonContainer,
  ReadMoreReadLessText,
  TitleHR,
  TitleValue,
  TitleKey,
  ApplicantViewContainer,
  RadioGroupTitle,
  RadioGroupCustom,
  IntrviewShowDetail,
  ViewDetailContainer,
  CustomDivider,
  TextInProfileCard,
  TextInProfile,
  TextInProfileSpan,
  FormField,
  SwitchWithTitle,
  CopyTextContainer,
  TextInTextIcon,
  TextWithIconContainer,
  NoDataContainer,
  SubTitle,
  SubTitleSpan,
  GroupButtonContainer,
  GroupOutlinedButton,
  OutlinedBtnForGroup,
  SolidBtnForGroup,
  SolidBtn,
  ModalTitle,
  ModalTitleSpan,
  ModalIconContainer,
  FormTitleContainer,
  ModalDataContainer,
  UserIconWithName,
  UserName,
  OutlinedBtn,
  LockButtonContainer,
  LottieContainer,
  FullScreenLoaderContent,
};
