import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { placeholder_name_without_id } from "../../Utils/mockData";

export default function SelectTextFields(props) {
  function getValue(data) {
    if (placeholder_name_without_id.includes(props.placeholder)) {
      props.onChange(data);
      return;
    }
    let filteredData = props.options.filter(
      (singleData) => props.getOptionLabel(singleData) === data
    );
    props.onChange(filteredData[0]);
  }

  return (
    <div style={props.style}>
      <label>{props.placeholder}</label>
      <Autocomplete
        disablePortal
        onChange={(value) => getValue(value.target.innerHTML)}
        value={props.value}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        onBlur={props.onBlur}
        // {...props}
        renderInput={(params) => <TextField {...params} />}
      />
      {/* {console.log(value?.outerText)} */}
    </div>
  );
}
{
  /* <div className="autocomplete" style={{ ...props.style }}>
      <label>{props.placeholder}</label>
      <select
        value={selectedValue}
        onChange={(e) => {
          props.onChange(e.target.value);
          setSelectedValue(e.target.value);
        }}
      >
        <option></option>
        {(props.options === undefined ? [] : props.options).map((data) => (
          <option value={checkValueName(data)}>
            {props.getOptionLabel(data)}
          </option>
        ))}
      </select>
    </div>
     <div className="autocomplete" style={{ marginTop: 10, ...props.style }}>
     <Autocomplete
       disableClearable
       size={CommonString.small}
       InputLabelProps={{ style: { fontSize: "12px !important" } }}
       InputProps={{ style: { fontSize: "12px !important" } }}
       className="selectData"
       onChange={props.onChange}
       defaultValue={props.defaultValue}
       value={props.value}
       options={props.options}
       getOptionLabel={props.getOptionLabel}
       onBlur={props.onBlur}
       inputProps={{ textTransform: "capitalize" }}
       {...props}
       renderInput={(params) => (
         <TextField
           hintStyle={{ fontSize: 12 }}
           inputStyle={{ fontSize: 12 }}
           size={CommonString.small}
           {...params}
           InputLabelProps={{ style: { fontSize: 12 } }}
           onKeyDown={props.onkeydown}
           error={props.error}
           label={props.placeholder}
         ></TextField>
       )}
     />
     </div> */
}
