import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import CustomButton from "../CommonComponet/Button";
import { formatPhoneNumber } from "../../services/Validate";
import { Grid } from "@material-ui/core";
import PartnerController from "../../controller/PartnerController";
import { useDispatch, useSelector } from "react-redux";
import {
  changeITempPartnerData,
  onChangePOCCount,
  onChangePOCDetail,
  onDisplayPocDetail,
} from "../../feature/store/ClientReduxStore";
import TaxTermComponent from "./ClientTaxTerm/TaxTermComponent";
import {
  displayPartnerId,
  removePartnerId,
} from "../../feature/store/JobReduxStore";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import { domain_list } from "../../Utils/mockData";
import ClientPOCDetail from "./ClientPOCDetail";
import { v4 as uuidv4 } from "uuid";
import { FormButton, POCButton } from "../../style/button.style";
import { PlaceholderName } from "../../Utils/CommonString";
import { SwitchWithTitle } from "../../style/common.style";
import CustomSwitch from "../CommonComponet/CustomSwitch";

export default function AddClient(props) {
  const dispatch = useDispatch();
  var partnerController = new PartnerController();
  const partner_id = useSelector((state) => state.JobReduxStore.partner_id);
  const poc_detail = useSelector((state) => state.ClientReduxStore.poc_detail);
  const poc_count = useSelector((state) => state.ClientReduxStore.poc_count);
  const [domain, setdomain] = useState();
  const [poc_name, setpoc_name] = useState();
  const [poc_phone, setpoc_phone] = useState();
  const [poc_email, setpoc_email] = useState();
  const [poc_designation, setpoc_designation] = useState();
  const [location, setlocation] = useState();
  const [clientName, setlientName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const [MarginPercentage, setMarginPercentage] = useState();
  const [selectedData, setselectedData] = useState(
    props.isUpdate ? JSON.parse(props.data.partner_id) : []
  );
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    onPrefilledData();
    getPartnerData();
    if (!props.isUpdate) {
      dispatch(removePartnerId());
    }
  }, []);

  async function getPartnerData() {
    var res = await partnerController.getPartners();
    var temp = [];
    for (let index = 0; index < res.length; index++) {
      const element = res[index];
      var d = {
        name: element.name,
        id: element._id,
        _1099: 0,
        w2_with_benifit: 0,
        w2_without_benifit: 0,
        w2_salarized: 0,
        margin: 0,
        is_in_percentage: true,
        working_percentage: 0,
      };
      temp.push(d);
    }
    dispatch(changeITempPartnerData(temp));
  }

  function onPrefilledData() {
    if ((props.isUpdate || props.isView) && props.data != null) {
      setlientName(props.data.name);
      setPhoneNo(formatPhoneNumber(props.data.phone));
      setpoc_name(props.data.poc_name);
      setpoc_email(props.data.poc_email);
      setpoc_designation(props.data.poc_designation);
      setpoc_phone(formatPhoneNumber(props.data.poc_phone));
      setdomain(props.data.domain);
      setlocation(props.data.location);
      setMarginPercentage(props.data.margin_percentage);
      setselectedData(selectedData);
      setIsActive(props.data._is_active);
      dispatch(
        displayPartnerId(
          props.data.partner_id == null ? [] : JSON.parse(props.data.partner_id)
        )
      );
      dispatch(
        onDisplayPocDetail(
          props.data.poc == null ? [] : JSON.parse(props.data.poc)
        )
      );
    }
  }

  function onAddClient() {
    if (!validate()) {
      return;
    }
    var phoneNumber =
      phoneNo !== undefined ? phoneNo.replace(/[^\d\+]/g, "") : "";
    var poc_phoneNumber =
      poc_phone !== undefined ? poc_phone.replace(/[^\d\+]/g, "") : "";
    var final_poc_detail = poc_detail.filter((data) => data.active !== false);
    var data = {
      name: clientName ?? "",
      email: "",
      phone: phoneNumber ?? "",
      partner_id: JSON.stringify(partner_id) ?? JSON.stringify([]),
      department: "",
      domain: domain ?? "",
      poc_name: poc_name ?? "",
      client_id: "",
      poc_phone: poc_phoneNumber ?? "",
      poc_email: poc_email ?? "",
      margin_percentage: MarginPercentage ?? "",
      poc_designation: poc_designation ?? "",
      location: location ?? "",
      poc: JSON.stringify(final_poc_detail),
      _is_active: isActive,
    };
    props.onSubmitData(data);
  }

  function validate() {
    if (clientName === null || clientName === undefined) {
      setERROR_NAME("CLIENT_NAME");
      return false;
    }
    setERROR_NAME("");
    return true;
  }

  function onAddPOC() {
    dispatch(onChangePOCCount(uuidv4()));
    var data = {
      active: true,
      count: poc_count,
      poc_name: "",
      poc_email: "",
      poc_detail: "",
      poc_designation: "",
    };
    dispatch(onChangePOCDetail(data));
  }
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={6} md={6}>
              <FormInput
                error={ERROR_NAME === "CLIENT_NAME"}
                value={clientName || ""}
                onChange={(e) => {
                  setlientName(e.target.value);
                  setERROR_NAME("");
                }}
                placeholder="Client Name *"
              />
              <FormInput
                error={ERROR_NAME === "PHONE_NUMBER"}
                value={phoneNo || ""}
                onChange={(e) => {
                  setPhoneNo(formatPhoneNumber(e.target.value));
                }}
                placeholder="Phone Number"
              />
              <SwitchWithTitle>
                {"Active Client\xa0\xa0\xa0:\xa0\xa0\xa0"}
                <CustomSwitch
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              </SwitchWithTitle>
            </Grid>
            <Grid item xs={6} md={6}>
              <SelectTextFields
                placeholder={PlaceholderName.domain}
                value={domain || ""}
                onChange={(value) => {
                  setdomain(value);
                  setERROR_NAME("");
                }}
                options={domain_list}
                getOptionLabel={(option) => option}
              />
              <FormInput
                value={location || ""}
                onChange={(e) => {
                  setlocation(e.target.value);
                  setERROR_NAME("");
                }}
                placeholder="Location"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {poc_detail.map((data, index) => (
                <ClientPOCDetail
                  key={index}
                  isUpdate={props.isUpdate}
                  data={data}
                />
              ))}
              <POCButton name="+ Add POC detail" onClick={(e) => onAddPOC(e)} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TaxTermComponent></TaxTermComponent>
        </Grid>
      </Grid>
      <FormButton>
        <CustomButton
          style={{ padding: "7px 63px", width: "auto" }}
          onClick={onAddClient}
          name={props.isUpdate ? "Update" : "Add"}
        />
      </FormButton>
    </div>
  );
}
