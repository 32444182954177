import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Icon } from "@iconify/react";
import { CommonString } from "../Utils/CommonString";
import { FormControl } from "react-bootstrap";

const Container = styled.div`
  color: white;
  height: 100vh;
  background: ${CommonColor.primary_color};
  width: 100%;
`;

const LeftMenu = styled.div`
  width: auto;
`;

const MenuItemContainer = styled.div`
  // padding: 5px;
  // padding-left: 25px;
`;

const RightTopMenu = styled.div`
  display: flex;
  align-items: center;
  height: 10vh;
  @media ${CommonString.mobile} {
    height: 50px;
  }
`;

const RightMenuIcon = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  width: 120px;
  position: absolute;
  right: 10px;
  display: ${(props) => (props.setX ? "none" : "flex")};
  height: 10vh;
`;

const MenuListContainer = styled.div`
  padding: 15px 0;
  padding-bottom: 15px;
  padding-top: 0;
`;

const NotificationMessageBox = styled.div`
  padding: 10px 20px;
  display: flex;
  align-items: center;
  width: 50ch;
`;
const ProfileBox = styled.div`
  padding: 10px 20px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  width: auto;
`;

const ProfileBoxContainer = styled.div`
  // display: flex;
  padding: 15px;
  margin-top: -10px;
  margin-bottom: -10px;
`;

const Avtar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  color: ${CommonColor.primary_color};
  background-color: #bcc4c8;
  margin-right: 10px;
  font-weight: bold;
  @media ${CommonString.mobile} {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;
const SearchInpute = styled.div`
  display: flex;
  text-align: right;
  width: 100%;
`;

const CardContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 7px 0;
  &:hover {
    color: #dde2ff;
  }
`;

const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  /* padding-left: 27px; */
  padding-right: 16px;
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#DDE2FF" : "#A4A6B3")};
  border-left: ${(props) => (props.isActive ? "3px solid" : "none")};
  padding-left: ${(props) => (props.isActive ? "24px" : "27px")};
  background: ${(props) => (props.isActive ? "#2F4758" : "none")};
  @media ${CommonString.mobile} {
    padding-left: 15px;
  }
`;

const ListItemIcon = styled.div`
  min-width: 56px;
  color: ${(props) => (props.isActive ? "#DDE2FF" : "#A4A6B3")};
  flex-shrink: 0;
  display: inline-flex;
`;

const ListItemText = styled.p`
  margin: 0;
  color: ${(props) => (props.isActive ? "#DDE2FF" : "#A4A6B3")};
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  /* font-size: 1rem; */
  line-height: 1.5;
  letter-spacing: 0.00938em;
  padding: 5px;
`;

const DrawerText = styled.p`
  display: ${(props) => (props.open ? "none" : "flex")};
  height: 66px !important;
  color: #dde2ff !important;
  margin: auto !important;
  align-items: center !important;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  @media ${CommonString.mobile} {
    height: 51px !important;
  }
`;

const DrawerImgContainer = styled.div`
  display: ${(props) => (!props.open ? "none" : "flex")};
  height: 66px;
  align-items: center;
  @media ${CommonString.mobile} {
    height: 50px;
  }
`;

const DrawerImg = styled.img`
  width: 120px;
  margin-left: 15px;
  @media ${CommonString.mobile} {
    width: 80px;
  }
`;

const DrawerMain = styled.div`
  padding: 0px;
  flex-grow: 3;
`;

const DrawerMainContainer = styled.div`
  display: flex;
`;

const DrawerLeftIcon = styled.div`
  display: ${(props) => (!props.open ? "none" : "flex")};
  width: auto;
  color: ${CommonColor.white};
  cursor: pointer;
`;

const VerticalMenuIcon = styled(MoreVertIcon)`
  color: ${CommonColor.primary_color};
  padding: 5px;
  height: 21px;
  width: 21px;
  border-radius: 50%;
  &:hover {
    background-color: ${CommonColor.primary_color_opacity};
  }
`;

const NotificationTitle = styled.p`
  font-weight: bold;
  color: ${CommonColor.primary_color};
  font-size: 14px;
`;

const AlertContainer = styled.div`
  position: static;
  background-color: ${(props) =>
    props.background ? props.background : CommonColor.primary_color};
  color: ${(props) => (props.color ? props.color : CommonColor.white)};
  padding: 10px;
  font-weight: bold;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgb(0 0 0 / 3%) 0px 2px 2px 2px;
  font-family: Arial;
  width: auto;
  box-sizing: border-box;
  margin: 10px;
  pointer-events: all;
  border: 5px solid ${CommonColor.primary_color};
  border-left: 10px solid ${CommonColor.primary_color};
  top: 0 !important;
  position: absolute !important;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  right: 0 !important;
  z-index: 999999;
`;
const NotificationSpan = styled.span`
  margin: 0;
  font-size: 13px;
  color: ${CommonColor.primary_color};
`;
const NotificationHR = styled.hr`
  margin: 0;
  border: 0.5px solid ${CommonColor.light_grey};
`;
const NoFoundImg = styled.img`
  width: 100%;
`;
const NotificationTitleMain = styled.h5`
  padding: 10px 20px;
  position: fixed;
  font-weight: bold;
  margin-top: -8px !important;
  width: 100% !important;
  color: #253f50;
  border-top-left-radius: 5px !important;
  z-index: 8;
`;
const Notification = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: -10px;
  margin-left: 5px;
  @media ${CommonString.mobile} {
    display: none;
  }
`;
const NotificationIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  margin-left: 10px;
  padding: 0px !important;
  cursor: pointer;
  color: ${CommonColor.primary_color} !important;
  @media ${CommonString.mobile} {
    margin-top: 5px !important;
    width: 19px;
    height: 19px;
  }
`;
const TopMenuIcon = styled(Icon)`
  height: 25px;
  width: 25px;
  margin: 10px !important;
  cursor: pointer;
  color: ${CommonColor.primary_color} !important;
  @media ${CommonString.mobile} {
    margin-top: 5px !important;
    width: 19px;
    height: 19px;
  }
`;
const SearchInputApplicant = styled(FormControl)`
  display: block;
  width: 100%;
  padding: 5px 10px !important;
  font-size: 1rem !important;
  line-height: 1.5;
  color: #7a7c7e;
  letter-spacing: 0.5px;
  background-clip: padding-box;
  border-bottom: transparent !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0rem !important;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
  &:focus {
    box-shadow: 0px 15px 10px -15px #cacaca96 !important;
  }
`;
const ApplicantFirstSearch = styled(FormControl)`
  display: block;
  width: 100%;
  padding: 5px 10px !important;
  font-size: 1rem !important;
  line-height: 1.5;
  color: #7a7c7e;
  letter-spacing: 0.5px;
  background-clip: padding-box;
  border-bottom: transparent !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0rem !important;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
  border-color: transparent !important;
  border-image: linear-gradient(
      to right,
      ${CommonColor.primary_color} 0%,
      ${CommonColor.primary_color} 25%,
      rgb(247, 247, 247) 50%,
      rgb(246, 248, 246) 75%,
      rgb(248, 248, 248) 100%
    )
    70 !important;
  border-width: 1px;
  border-style: solid;
  box-shadow: 0px 15px 10px -15px #cacaca96 !important;
  &:focus {
    box-shadow: 0px 15px 10px -15px #cacaca96 !important;
  }
`;
const SearchInput = styled(FormControl)`
  display: block;
  width: 100%;
  padding: 1rem 10px !important;
  font-size: 1.5rem !important;
  line-height: 1.5;
  color: #7a7c7e;
  letter-spacing: 0.5px;
  background-clip: padding-box;
  border: none !important;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0rem !important;
  transition: border-color 0.15s ease-in-out box-shadow 0.15s ease-in-out;
  height: 10vh;
  &:focus {
    color: white !important;
    background: linear-gradient(
      90deg,
      ${CommonColor.primary_color},
      ${CommonColor.primary_color},
      #ffffff,
      #fafafa
    );
    background-size: 200% 200%;
    animation: gradient 1s;
    height: 100;
    z-index: 2;
    color: white;
    box-shadow: 0px 15px 10px -15px #cacaca96 !important;
  }
  &:hover {
    box-shadow: 0px 15px 10px -15px #cacaca96;
  }
  @media ${CommonString.mobile} {
    font-size: 0.8rem !important;
    height: 54px;
  }
`;
const SearchApplicantIcon = styled(Icon)`
  width: 35px;
  height: 35px;
  color: ${CommonColor.white};
  background-color: ${CommonColor.secondary_color};
  padding: 7px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
`;
export {
  SearchApplicantIcon,
  SearchInput,
  ApplicantFirstSearch,
  SearchInputApplicant,
  TopMenuIcon,
  NotificationIcon,
  Notification,
  NotificationTitleMain,
  NoFoundImg,
  NotificationHR,
  NotificationSpan,
  AlertContainer,
  NotificationTitle,
  VerticalMenuIcon,
  DrawerLeftIcon,
  DrawerMainContainer,
  DrawerMain,
  DrawerImgContainer,
  DrawerImg,
  DrawerText,
  ProfileBoxContainer,
  ProfileBox,
  RightMenuIcon,
  ListItemText,
  ListItemIcon,
  CardContent,
  SearchInpute,
  Avtar,
  NotificationMessageBox,
  MenuListContainer,
  RightTopMenu,
  MenuItemContainer,
  Container,
  LeftMenu,
  ListItem,
};
