import ApiUtils from "../APICallServices/APIUtils";

export default class JobProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getMonthJobReport = (data) => {
    return this.Apiprovider.init().post("report/get-month-job-report", data);
  };
  getYearJobReport = (data) => {
    return this.Apiprovider.init().post("report/get-year-job-report", data);
  };
  getMonthSubmissionReport = (data) => {
    return this.Apiprovider.init().post(
      "report/get-month-submission-report",
      data
    );
  };
  getYearSubmissionReport = (data) => {
    return this.Apiprovider.init().post(
      "report/get-year-submission-report",
      data
    );
  };
  //getReport
  getReport = (data) => {
    return this.Apiprovider.init().post("/v2/submissionV2/get-report", data);
  };
  getYearReport = (data) => {
    return this.Apiprovider.init().post(
      "/v2/submissionV2/get-year-report",
      data
    );
  };
  getCallReport = () => {
    return this.Apiprovider.init().get("/v2/call_tracker/get");
  };
  addCallReport = (data) => {
    return this.Apiprovider.init().post("/v2/call_tracker/add", data);
  };
  editCallReport = (data) => {
    return this.Apiprovider.init().post("/v2/call_tracker/update", data);
  };
  deleteCallReport = (data) => {
    return this.Apiprovider.init().post("/v2/call_tracker/delete", data);
  };
  getCallReportByUserIdAndDate = (data) => {
    return this.Apiprovider.init().post("v2/call_tracker/get-by-user_id", data);
  };
}
