import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useState } from "react";
import BaseModal from "../Modal/BaseModal";
import JobViewDetails from "../ViewDetails/JobViewDetails";
import SubmissionProvider from "../../services/provider/SubmissionProvider";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import {
  UPDATE_SUBMISSION_SUCCESS,
  UPDATE_SUBMISSION_ERROR,
  SELECTE_JOB,
} from "../../Utils/alertMessage";
import JobController from "../../controller/JobController";
import LoadingBar from "react-top-loading-bar";
import {
  addSubmissionData,
  changeMenuStatus,
} from "../../feature/store/ReduxStore";
import SelectPartner from "../Modal/SelectPartner";
import {
  SELECTE_PARTNER,
  SUBMISSION,
  UPDATE_APPLICANTS,
  VIEW_JOBS,
  VIEW_SUBMISSION,
} from "../../Utils/enum";
import JobTableForViewApplicant from "../DataTable/Table/JobTableForViewApplicant";
import ApplicantData from "./view_component/ApplicantData";
import {
  changeSelectedRecruiterId,
  changeTempJobList,
  changeTempRecruiterList,
} from "../../feature/store/JobReduxStore";
import RecruiterController from "../../controller/RecruiterController";
import {
  ApplicantViewContainer,
  LockButtonContainer,
} from "../../style/common.style";
import ButtonGroup from "../CommonComponet/ButtonGroup";
import {
  CommonButtonName,
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { CommonColor } from "../../Utils/color";
import { SUBMISSION_STATUS } from "../../Utils/constant";
import { TableMainContainer } from "../../style/table.styled";
import { changeJobData } from "../../feature/store/ApplicantStore";
import { checkNullValidation } from "../../Utils/validation";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import UpdateStatusConfirmation from "../Modal/UpdateStatusConfirmation";
import { IsOpenAlertBox } from "../../Utils/utilitiFunction";
import SubmissionViewDetails from "./SubmissionViewDetails";
import { SubmissionController } from "../../controller/SubmissionController";

export default function LockApplicant(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const selectedJobForLockApplicant = useSelector(
    (state) => state.JobReduxStore.selectedJobForLockApplicant
  );
  const submissionProvider = new SubmissionProvider();
  const recruiterController = new RecruiterController();
  const jobController = new JobController();
  const jobData = useSelector((state) => state.counter.jobData);
  const [selectedJob, setSelectedJob] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [progress, setProgress] = useState(0);
  const [modalStatus, setModalStatus] = useState();
  const [statusDetails, setStatusDetails] = useState("");
  const [isOpenAlertBox, setIsOpenAlertBox] = useState(false);
  const [content, setContent] = useState("");

  useEffect(() => {
    setSearchResult(jobData);
    getData();
    getJobData();
  }, []);

  async function getJobData() {
    var data = await jobController.getOpenJobs();
    dispatch(changeJobData(data));
  }

  function onSubmit(e) {
    setIsOpenAlertBox(true);
    setStatusDetails(e);
  }
  async function onUpdateData(e) {
    dispatch(onChangeIsLoading(true));
    if (checkNullValidation(selectedJobForLockApplicant)) {
      alert.show(SELECTE_JOB, { type: CommonString.info });
      dispatch(onChangeIsLoading(false));
      return;
    }
    var user = localStorage.getItem(CommonStringForLocalStorage.user);
    if (
      selectedJobForLockApplicant === null ||
      (e !== SUBMISSION_STATUS.IN_TOUCH && e.payrate !== undefined)
    ) {
      alert.show(SELECTE_JOB, { type: CommonString.info });
      dispatch(onChangeIsLoading(false));
      return;
    }
    var data = {
      applicant_name: `${applicant.first_name} ${applicant.last_name}`,
      full_name: `${applicant.first_name} ${applicant.last_name}`,
      job_name: selectedJobForLockApplicant.job_detail.position_title,
      position_id: selectedJobForLockApplicant.job_detail.client_position_id,
      parent_job_id: selectedJobForLockApplicant.job_detail._id,
      child_job_id: selectedJobForLockApplicant._id,
      client_id: selectedJobForLockApplicant.job_detail.client._id,
      client_name: selectedJobForLockApplicant.job_detail.client.name,
      recruiter_id: JSON.parse(user).user._id,
      payrate: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.payRate,
      tax_term: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.tax_term,
      applicant_id: applicant._id,
      partner_id: selectedJobForLockApplicant.partner._id,
      applicant_first_name: applicant.first_name,
      applicant_last_name: applicant.last_name,
      department: selectedJobForLockApplicant.job_detail.department,
      position_title: selectedJobForLockApplicant.job_detail.position_title,
      job_id: selectedJobForLockApplicant.job_detail._id,
      job_title: selectedJobForLockApplicant.job_detail.position_title.trim(),
      partner_name: selectedJobForLockApplicant.partner.name,
      status:
        e === SUBMISSION_STATUS.IN_TOUCH
          ? SUBMISSION_STATUS.IN_TOUCH
          : SUBMISSION_STATUS.LOCKED,
      recruiter_name: JSON.parse(user).user.name,
      recruiter_email: JSON.parse(user).user.email,
      pay_rate: e === SUBMISSION_STATUS.IN_TOUCH ? 0 : e.payRate,
      comment: e?.comment ?? content,
    };
    var res = await submissionProvider.addSubmission(data);
    if (res.status === 200) {
      var partner_data = {
        ...res.data.data,
        position_id: selectedJobForLockApplicant.job_detail.client_position_id,
        client_id: selectedJobForLockApplicant.job_detail.client._id,
        client_name: selectedJobForLockApplicant.job_detail.client.name,
      };
      dispatch(addSubmissionData(partner_data));
      setisOpenModal(false);
      // dispatch(onChangeIsLoading(false));
      dispatch(changeMenuStatus(SUBMISSION));
      alert.show(UPDATE_SUBMISSION_SUCCESS, { type: CommonString.success });
    } else {
      dispatch(onChangeIsLoading(false));
      alert.show(UPDATE_SUBMISSION_ERROR, { type: CommonString.error });
    }
  }
  function onViewModal(modalSt, data) {
    setisOpenModal(!isopenModal);
    setModalStatus(modalSt);
    setselectedData(data);
  }
  function closeSearchBox() {
    setSearchResult(jobData);
  }
  // async function onSearchJob(search_text) {
  //   if (search_text.length < 3) {
  //     setProgress(10);
  //     setSearchResult(jobData);
  //   }
  //   setisLoading(true);
  //   if (search_text.length < 2) {
  //     setProgress(0);
  //     setisLoading(false);
  //     return;
  //   }
  //   var sendData = {
  //     search_query: search_text,
  //   };
  //   setProgress(70);
  //   var res = await jobController.searchJob(sendData);
  //   setSearchResult(res);
  //   setProgress(100);
  //   setisLoading(false);
  // }

  async function getData() {
    var data = await recruiterController.getRecruiters();
    const temprecruiterlist = [];
    temprecruiterlist.push(
      { name: "All", _id: "123", is_recruiter: "true" },
      ...data
    );
    dispatch(changeTempRecruiterList(temprecruiterlist));
    var user = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    dispatch(changeSelectedRecruiterId(user.user._id));
    dispatch(changeTempJobList(jobData));
  }

  function getTitle() {
    if (modalStatus === VIEW_JOBS) {
      return VIEW_JOBS;
    }
    if (modalStatus === VIEW_SUBMISSION) {
      return VIEW_SUBMISSION;
    }
    if (modalStatus === SELECTE_PARTNER) {
      return SELECTE_PARTNER;
    }
    return SELECTE_PARTNER;
  }
  function getFormView() {
    if (modalStatus === VIEW_JOBS) {
      return <JobViewDetails data={selectedData} />;
    }
    if (modalStatus === VIEW_SUBMISSION) {
      return (
        <SubmissionViewDetails
          handleClose={() => setisOpenModal(!isopenModal)}
          isLoading={(e) => setisLoading(e)}
          onRefresh={() => props.onRefresh()}
          data={selectedData}
        />
      );
    }
    return <SelectPartner onSubmit={onSubmit} data={jobData[selectedJob]} />;
  }
  // function getSubmission() {
  //   var data = job_data_for_applicant.filter(
  //     (data) =>
  //       data.recruiter._id === selected_recruiter_id ||
  //       selected_recruiter_id.includes("123")
  //   );
  //   return data;
  // }
  var status_name =
    statusDetails === SUBMISSION_STATUS.IN_TOUCH
      ? SUBMISSION_STATUS.IN_TOUCH
      : SUBMISSION_STATUS.LOCKED;

  return (
    <div>
      <UpdateStatusConfirmation
        isLoading={false}
        status={status_name}
        possition_id={
          selectedJobForLockApplicant?.job_detail?.client_position_id
        }
        onClose={() => setIsOpenAlertBox(false)}
        onDelete={() => {
          onUpdateData(statusDetails);
          setIsOpenAlertBox(false);
        }}
        isOpenAlertBox={isOpenAlertBox}
        content={content}
        setContent={(e) => setContent(e)}
        enableInput={IsOpenAlertBox(status_name)}
      />
      <LoadingBar
        style={{ height: 4 }}
        color={CommonColor.border}
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <BaseModal
        isUpdate={modalStatus === UPDATE_APPLICANTS}
        isLoading={isLoading}
        isBig={
          modalStatus === VIEW_JOBS || modalStatus === VIEW_SUBMISSION
            ? true
            : false
        }
        open={isopenModal}
        formTitle={getTitle()}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {getFormView()}
      </BaseModal>
      <ApplicantViewContainer onClick={closeSearchBox}>
        <Grid container>
          <Grid item xs={12} md={4}>
            <ApplicantData
              onViewModal={onViewModal}
              isLockView={true}
              data={applicant}
            />
          </Grid>
          <Grid item md={8} xs={12}>
            <TableMainContainer>
              <JobTableForViewApplicant onViewModal={onViewModal} />
              <LockButtonContainer>
                <ButtonGroup
                  solidBtnClick={() => {
                    if (checkNullValidation(selectedJobForLockApplicant)) {
                      alert.show(SELECTE_JOB, { type: CommonString.info });
                      setisLoading(false);
                      return;
                    }
                    onViewModal(SELECTE_PARTNER, selectedData);
                  }}
                  outlinedBtnClick={() => onSubmit(SUBMISSION_STATUS.IN_TOUCH)}
                  solidBtnName={CommonButtonName.lock_profile}
                  outlinedBtnName={CommonButtonName.in_touch}
                />
              </LockButtonContainer>
            </TableMainContainer>
          </Grid>
        </Grid>
      </ApplicantViewContainer>
    </div>
  );
}
