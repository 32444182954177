import React, { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { useSelector, useDispatch } from "react-redux";
import { DELETE_JOB_ERROR, DELETE_JOB_SUCCESS } from "../../Utils/alertMessage";
import JobController from "../../controller/JobController";
import { useAlert } from "react-alert";
import {
  deletejob,
  onChangePageNumber,
  removeJObData,
} from "../../feature/store/ReduxStore";
import JobTable from "./Table/JobTable";
import RecruiterController from "../../controller/RecruiterController";
import PartnerController from "../../controller/PartnerController";
import {
  changeFilterData,
  changeIsLoading,
  changeSelectedData,
  changeTempPartnerList,
  changeTempRecruiterList,
  deleteSearchJob,
} from "../../feature/store/JobReduxStore";
import { CommonString } from "../../Utils/CommonString";
import { TableMainContainer } from "../../style/table.styled";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import CustomizeTableFiled from "../jobs/CustomizeTableFiled";
import { ClearFilterText } from "../../style/common.style";
import { FlexContainer } from "../../style/chat.style";

export default function JobDataTable(props) {
  const recruiterController = new RecruiterController();
  const partnerController = new PartnerController();
  const search_result = useSelector(
    (state) => state.JobReduxStore.search_result
  );
  const jobList = useSelector((state) => state.counter.jobData);
  const is_searching = useSelector(
    (state) => state.ApplicantStore.is_searching
  );
  const filter_data = useSelector((state) => state.JobReduxStore.filter_data);
  const job_count = useSelector((state) => state.counter.job_count);
  const alert = useAlert();
  const dispatch = useDispatch();
  const jobController = new JobController();

  useEffect(() => {
    getData();
  }, []);

  function onViewModal(modalSt, data) {
    dispatch(changeSelectedData(data));
  }

  async function getData() {
    var data = await recruiterController.getRecruiters();
    var partnerData = await partnerController.getPartners();
    const tempPartnerList = [];
    const temprecruiterlist = [];
    tempPartnerList.push(
      { name: CommonString.all_uppercase, _id: "123" },
      ...partnerData
    );
    temprecruiterlist.push(
      { name: "All", _id: "123", is_recruiter: 1 },
      ...data
    );
    dispatch(changeTempPartnerList(tempPartnerList));
    dispatch(changeTempRecruiterList(temprecruiterlist));
  }

  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            name={data.job_detail.client_position_id}
            onClose={onClose}
            onDelete={() => {
              OndeleteData(data);
              onClose();
            }}
          />
        );
      },
    });
  }
  async function OndeleteData(data) {
    try {
      dispatch(onChangeIsLoading(true));
      var sendData = {
        _id: data._id,
      };
      var res = await jobController.deleteJob(sendData);
      if (res.data.status === 200) {
        dispatch(deleteSearchJob(data._id));
        dispatch(deletejob(data._id));
        alert.show(DELETE_JOB_SUCCESS, { type: CommonString.success });
        dispatch(onChangeIsLoading(false));
      } else {
        alert.show(DELETE_JOB_ERROR, { type: CommonString.error });
        dispatch(onChangeIsLoading(false));
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function onClickClearFilter() {
    if (
      filter_data.status === "ALL" &&
      filter_data.order === "ASC" &&
      filter_data.partner_id === "123" &&
      filter_data.recruiter_id === "123"
    ) {
      return;
    }
    var sendData = {
      status: "ALL",
      recruiter_id: "123",
      order: "ASC",
      partner_id: "123",
    };
    dispatch(changeIsLoading(true));
    dispatch(removeJObData([]));
    dispatch(changeFilterData(sendData));
    dispatch(onChangePageNumber(1));
    let res = await jobController.getJobsByStatus(sendData, 1);
    dispatch(changeIsLoading(false));
  }

  return (
    <>
      <FlexContainer>
        <p style={{ marginLeft: 10 }}>
          {CommonString.total_job_count} : {job_count}
        </p>
        <FlexContainer>
          <ClearFilterText onClick={() => onClickClearFilter()}>
            {CommonString.clear_filter}
          </ClearFilterText>
          <CustomizeTableFiled />
        </FlexContainer>
      </FlexContainer>
      <TableMainContainer>
        <JobTable
          height={"85vh"}
          onRefresh={() => props.onRefresh()}
          onDelete={onDelete}
          onViewModal={onViewModal}
          jobList={is_searching ? search_result : jobList}
        />
      </TableMainContainer>
    </>
  );
}
