import React, { useState, useEffect } from "react";
import SubmissionViewDetails from "../ViewDetails/SubmissionViewDetails";
import BaseModal from "../Modal/BaseModal";
import { useSelector, useDispatch } from "react-redux";
import SubmissionTable from "./Table/SubmissionTable";
import { CommonString } from "../../Utils/CommonString";
import {
  changeSelectedSubmission,
  changeStatusList,
} from "../../feature/store/SubmissionReduxStore";
import {
  SUBMISSION_STATUS_FOR_MANAGER,
  SUBMISSION_STATUS_FOR_RECRUITER,
} from "../../Utils/constant";
import AddInterviewDetail from "../Modal/AddInterviewDetail";
import { ADD_INTERVIEW_DETAIL } from "../../Utils/enum";
import {
  changeSelectedRecruiterId,
  changeTempRecruiterList,
} from "../../feature/store/JobReduxStore";
import RecruiterController from "../../controller/RecruiterController";
import { confirmAlert } from "react-confirm-alert";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { SubmissionController } from "../../controller/SubmissionController";
import {
  DELETE_SUBMISSION_ERROR,
  DELETE_SUBMISSION_SUCCESS,
} from "../../Utils/alertMessage";
import { useAlert } from "react-alert";
import { deleteSubmission } from "../../feature/store/ReduxStore";
import { getFormTitleForSubmission } from "../../Utils/utilitiFunction";
import { TableMainContainer } from "../../style/table.styled";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";

export default function SubmissionDataTable(props) {
  const submissionController = new SubmissionController();
  const recruiterController = new RecruiterController();
  const isVisible = useSelector((state) => state.counter.is_visible);
  const dispatch = useDispatch();
  const alert = useAlert();
  const selected_recruiter_id = useSelector(
    (state) => state.JobReduxStore.selected_recruiter_id
  );
  const is_searching = useSelector(
    (state) => state.ApplicantStore.is_searching
  );
  const tsubmissionList = useSelector((state) => state.counter.submissionData);
  const interview_details = useSelector(
    (state) => state.SubmissionReduxStore.interview_details
  );
  const search_result = useSelector(
    (state) => state.SubmissionReduxStore.search_result
  );
  const selected_submission = useSelector(
    (state) => state.SubmissionReduxStore.selected_submission
  );
  const [modalStatus, setModalStatus] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  // const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getStatusList();
    getRecruiterData();
    dispatch(changeSelectedRecruiterId("123"));
  }, []);

  function onViewSubmission(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    dispatch(changeSelectedSubmission(data));
    // setselectedData(data);
  }

  function getsubmissionList() {
    var data = is_searching ? search_result : tsubmissionList;
    if (data === undefined) return;
    var filteredData = data.filter(
      (person) =>
        person.recruiter_id === selected_recruiter_id ||
        selected_recruiter_id === "123"
    );
    return filteredData;
  }

  async function getRecruiterData() {
    var data = await recruiterController.getRecruiters();
    const temprecruiterlist = [];
    temprecruiterlist.push(
      { name: "All", _id: "123", is_recruiter: CommonString.true ? 1 : 0 },
      ...data
    );
    dispatch(changeTempRecruiterList(temprecruiterlist));
  }

  function getStatusList() {
    const tempStatusList = [];
    var submissionList = isVisible
      ? SUBMISSION_STATUS_FOR_MANAGER
      : SUBMISSION_STATUS_FOR_RECRUITER;
    tempStatusList.push("ALL", ...submissionList);
    dispatch(changeStatusList(tempStatusList));
  }

  async function AddInterviewDetails() {
    props.isLoading(true);
    setTimeout(() => {
      isLoading(false);
      setisOpenModal(!isopenModal);
    }, 1000);
  }
  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            isLoading={isLoading}
            name={data.full_name}
            onClose={onClose}
            onDelete={() => {
              OndeleteData(data);
              setisOpenModal(true);
              onClose();
              setisOpenModal(false);
            }}
          />
        );
      },
    });
  }
  async function OndeleteData(data) {
    dispatch(onChangeIsLoading(true));
    var sendData = {
      _id: data._id,
    };
    var res = await submissionController.deleteSubmission(sendData);
    if (res.data.status === 200) {
      alert.show(DELETE_SUBMISSION_SUCCESS, { type: CommonString.success });
      dispatch(deleteSubmission(data._id));
      dispatch(onChangeIsLoading(false));
    } else {
      alert.show(DELETE_SUBMISSION_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
  }

  return (
    <>
      <BaseModal
        isLoading={isLoading}
        isBig={modalStatus === ADD_INTERVIEW_DETAIL ? false : true}
        open={isopenModal}
        formTitle={
          modalStatus === ADD_INTERVIEW_DETAIL
            ? CommonString.update_interview_details
            : getFormTitleForSubmission(selected_submission)
        }
        data={selected_submission}
        isSubmissionView={true}
        submissionList={props.submissionList}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {modalStatus === ADD_INTERVIEW_DETAIL ? (
          <AddInterviewDetail
            AddInterviewDetails={(e) => AddInterviewDetails(e)}
            isLoading={props.isLoading}
            modalStatus={ADD_INTERVIEW_DETAIL}
            data={interview_details}
          />
        ) : (
          <SubmissionViewDetails
            handleClose={() => setisOpenModal(!isopenModal)}
            onClickEdit={(e) => onViewSubmission(ADD_INTERVIEW_DETAIL, e)}
            isLoading={(e) => setisLoading(e)}
            onRefresh={() => props.onRefresh()}
            data={selected_submission}
          />
        )}
      </BaseModal>
      {/* {props.hideToolBar == true ? (
        <div />
      ) : (
        <TableToolbar
          pageNumber={submissionPagenumber}
          isloading={props.isLoading}
          title={CONSULTANT_STATUS}
          buttonName={CommonString.submission}
        />
      )} */}
      <TableMainContainer>
        <SubmissionTable
          onSearch={() => props.onSearch()}
          onRefresh={() => props.onRefresh()}
          isLoading={props.isLoading}
          submissionList={getsubmissionList()}
          onViewSubmission={onViewSubmission}
          onDelete={onDelete}
        />
      </TableMainContainer>
    </>
  );
}
