import React, { useEffect, useState } from "react";
import FormInput from "../CommonComponet/FormInput";
import { DateContainer } from "../../style/common.style";
import CustomDatePicker from "../CommonComponet/DatePicker";
import CustomButton from "../CommonComponet/Button";
import { SubmissionController } from "../../controller/SubmissionController";
import { CommonString } from "../../Utils/CommonString";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { changeProjectDetails } from "../../feature/store/SubmissionReduxStore";
import { SUBMISSION_STATUS } from "../../Utils/constant";
import CommonDatePicker from "../CommonComponet/CommonDatePicker";

export default function AddProjectDetail(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const submissionController = new SubmissionController();
  const [note, setNote] = useState("");
  const [endDate, setEndDate] = useState(null);
  const is_update = useSelector(
    (state) => state.SubmissionReduxStore.is_update
  );
  const project_details = useSelector(
    (state) => state.SubmissionReduxStore.project_details
  );
  useEffect(() => {
    onPrefilledData();
  }, [project_details]);

  async function onPrefilledData() {
    if (project_details === undefined || project_details === null) return;
    if (is_update) {
      setEndDate(project_details.project_end_date);
      setNote(project_details.note);
    }
  }
  async function onSave() {
    if (endDate === null) {
      alert.show("Please add end date.", {
        type: CommonString.info,
      });
      return;
    }
    var data = {
      submission_id: props.submission_id,
      project_end_date: endDate,
      note: note,
      status: props.submissionStatus,
      comment: note,
    };
    if (is_update) {
      var sendData = {
        _id: project_details._id,
        ...data,
      };
      var res = await submissionController.updateProjectCompletedDetail(
        sendData
      );
      if (res.status === 200) {
        dispatch(changeProjectDetails(sendData));
      }
      props.handleClose();
      return;
    }
    var add_res = await submissionController.addProjectCompletedDetail(data);
    props.onSubmit(props.submissionStatus, data, add_res);
    props.handleClose();
  }
  function getLabelName(status) {
    if (status === SUBMISSION_STATUS.DECLINED_BY_CLIENT) {
      return "Declined By Client Date";
    } else if (status === SUBMISSION_STATUS.DECLINED_BY_CONSULTANT) {
      return "Declined By Consultant Date";
    } else if (status === SUBMISSION_STATUS.TERMINATED) {
      return "Terminated Date";
    } else if (status === SUBMISSION_STATUS.RESIGNED) {
      return "Resigned Date";
    } else {
      return "Project End";
    }
  }
  return (
    <>
      <CommonDatePicker
        margin={50}
        label={getLabelName(props.submissionStatus)}
        name={getLabelName(props.submissionStatus)}
        value={endDate}
        onChange={(date) => setEndDate(date)}
      />
      <FormInput
        multiline
        value={note || ""}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Note"
      />
      <CustomButton onClick={() => onSave()} name={"Save"} />
    </>
  );
}
