import React, { useEffect, useState } from "react";
import {
  Table,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import { submissionStatuses } from "../../Utils/constant";
import { CommonColor } from "../../Utils/color";
import FilterForJob from "../jobs/FilterForJob";
import { useSelector } from "react-redux";

export default function ReportTable(props) {
  const [selectedName, setSelectedName] = useState("All");
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  useEffect(() => {
    getFilteredData();
  }, [selectedName]);

  function getColor(status, count) {
    // eslint-disable-next-line eqeqeq
    if (status == "PLACEMENT" && count >= 1) {
      return {
        backgroundColor: CommonColor.bg_green,
        color: CommonColor.green,
      };
    }
    if (status == "OFFER" && count >= 1) {
      return {
        backgroundColor: CommonColor.bg_blue,
        color: CommonColor.blue,
      };
    }
    if (status == "INTERVIEW" && count >= 1) {
      return {
        backgroundColor: CommonColor.bg_yellow,
        color: CommonColor.bg_yellow_text_color,
      };
    }
    return {};
  }
  function getFilteredData() {
    if (selectedName === "All") {
      return props.allReportData;
    }
    var data = props.allReportData.filter(
      (singleData) => singleData?.name === selectedName
    );
    return data;
  }
  return props.allReportData.length == 0 ? (
    <></>
  ) : (
    <Table>
      <TableTbody>
        <TableRow style={{ top: "-10px" }}>
          <TableTh>
            <FilterForJob
              list={temp_recruiter_list}
              getSelectedName={(e) => setSelectedName(e?.name)}
              name={"Recruiter"}
            />
          </TableTh>
          {submissionStatuses.map((singleTH, index) => (
            <TableTh align={"center"} key={index}>
              {singleTH}
            </TableTh>
          ))}
        </TableRow>
        {getFilteredData().map((data, index) => {
          return (
            <TableRow key={index}>
              <TableTd textAlignLeft={true}>{data.name}</TableTd>
              <TableTd isCenter={true}>{data.SUBMISSION}</TableTd>
              {/* <TableTd isCenter={true}>{data["REJECTED BY CLIENT"]}</TableTd> */}
              <TableTd
                isCenter={true}
                style={getColor("INTERVIEW", data.INTERVIEW)}
              >
                {data.INTERVIEW}
              </TableTd>
              {/* <TableTd isCenter={true}>{data["INTERVIEW NO SHOW"]}</TableTd> */}
              <TableTd style={getColor("OFFER", data.OFFER)} isCenter={true}>
                {data.OFFER}
              </TableTd>
              {/* <TableTd isCenter={true}>{data["DECLINED/BD BY CLIENT"]}</TableTd> */}
              {/* <TableTd isCenter={true} >{data["DECLINED/BD BY CONSULTANT"]}</TableTd> */}
              <TableTd
                isCenter={true}
                style={getColor("PLACEMENT", data.PLACEMENT)}
              >
                {data.PLACEMENT}
              </TableTd>
              {/* <TableTd isCenter={true}>{data.TERMINATED}</TableTd> */}
              {/* <TableTd isCenter={true}>{data.RESIGNED}</TableTd> */}
              <TableTd isCenter={true}>{data["PROJECT END"]}</TableTd>
            </TableRow>
          );
        })}
      </TableTbody>
    </Table>
  );
}
