import { Grid } from "@mui/material";
import moment from "moment-timezone";
import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { DateObject } from "react-multi-date-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { REPORTCALENDARALERTMESSAGE } from "../../Utils/alertMessage";
import { TYPE } from "../../Utils/constant";
import ReportController from "../../controller/ReportController";
import { FlexContainer, ReportContainer } from "../../style/common.style";
import {
  DatePickerCalendar,
  OutlinedButtonContainer,
  ReportTableContainer,
} from "../../style/report.style";
import CustomButton from "../CommonComponet/Button";
import FormInput from "../CommonComponet/FormInput";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import TopMenu from "../Menu/TopMenu";
import CommonBaseModal from "../Modal/CommonBaseModal";
import { TopFixedMenu } from "./StyledView";
import ReportTable from "../Report/ReportTable";
import { Icon } from "@iconify/react";
import xlsx from "json-as-xlsx";
import dayjs from "dayjs";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { getVisibility } from "../../feature/store/ReduxStore";
import TotalCountCards from "../Report/TotalCountCards";
import { REPORT } from "../../Utils/enum";
import LoaderForTable from "../CommonComponet/LoaderForTable";
function getCurrentWeekDates() {
  const currentDate = new Date();
  const startOfWeek = currentDate.getDate() - currentDate.getDay(); // Monday as the first day
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(currentDate);
    date.setDate(startOfWeek + i);
    weekDates.push(date);
  }
  var dates = [weekDates[0], weekDates[6]];
  return dates;
}
export default function ReportView(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [type, setType] = useState(TYPE[0]);
  const [loading, setLoading] = useState(false);
  const date = useRef(new DateObject());
  const reportController = new ReportController();
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const hasAccess = onCheckLevelAccessibility();
  const all_recruiter = {
    name: "All",
    _id: "123",
    is_recruiter: 1,
  };
  const all_partner = {
    name: "All",
    _id: "123",
  };
  const all_client = {
    name: "All",
    _id: "123",
  };
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const temp_partner_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_partner_data
  );
  const temp_client_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_client_data
  );
  const defaultWeekDates = getCurrentWeekDates();
  const [statusData, setStatusData] = useState([]);
  const [addedApplicant, setAddedApplicant] = useState(0);
  const [jobCountData, setJobCountData] = useState([]);
  const [totalSubmit, setTotalSubmit] = useState([]);
  const [allRecruiterReportData, setAllRecruiterReportData] = useState([]);
  const [isRotate, setIsRotate] = useState(false);
  const [recruiter, setRecruiter] = useState(all_recruiter);
  const [partner, setPartner] = useState(all_partner);
  const [client, setClient] = useState(all_client);
  const [selectedDates, setSelectedDates] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  // const [data, setData] = useState({
  //   recruiter_id: recruiter._id,
  //   from_date: new DateObject(),
  //   to_date: new DateObject(),
  // });
  const data = useRef({
    recruiter_id: hasAccess ? "123" : recruiter._id,
    from_date: moment(date).startOf("year").format(),
    to_date: moment(date).endOf("year").format(),
    partner_id: "123",
    client_id: "123",
  });
  useEffect(() => {
    setSelectedDates(date.current);
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    const from_date = moment().startOf("year").format();
    const end_date = moment().endOf("year").format();
    const current_recruiter = JSON.parse(
      localStorage.getItem(CommonStringForLocalStorage.user)
    );
    setRecruiter(hasAccess ? all_recruiter : current_recruiter.user);
    const sendData = {
      recruiter_id: hasAccess ? "123" : current_recruiter.user._id,
      from_date: moment.tz(from_date, "UTC").format(),
      to_date: moment.tz(end_date, "UTC").format(),
      partner_id: "123",
      client_id: "123",
    };
    data.current = sendData;
    getYearInitalResponse(sendData);
    getFirstResponse(sendData);
  }, []);

  async function getYearInitalResponse(sendData) {
    try {
      const data = await reportController.getYearReport(sendData);
      if (data) {
        setAllRecruiterReportData(data);
      }
    } catch (error) {}
  }
  async function getFirstResponse(sendData) {
    setLoading(true);
    const res = await reportController.getReport(sendData);
    if (res) {
      filterSubmitData(res.Total_Submit);
      setTotalSubmit(res.Total_Submit);
      setAddedApplicant(res.Total_Applicant_Added);
      const newAssignedJobData = res.Assigned_Job.map((ele) => {
        return {
          month: ele.month.substring(0, 3),
          count: ele.count,
        };
      });
      setJobCountData(newAssignedJobData);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  function filterSubmitData(data) {
    const status = data.filter((submit) => {
      return (
        submit.status === "SUBMISSION" ||
        submit.status === "INTERVIEW" ||
        submit.status === "OFFER" ||
        submit.status === "PLACEMENT"
      );
    });
    setStatusData(status);
    return status;
  }
  function getDateRange(date, typeSent) {
    let from_date, to_date;
    if (typeSent === TYPE[0]) {
      from_date = moment(date).startOf("year").format();
      to_date = moment(date).endOf("year").format();
    } else if (typeSent === TYPE[1]) {
      const firstDateOfMonth = (date = new Date()) =>
        new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDateOfMonth = (date = new Date()) =>
        new Date(date.getFullYear(), date.getMonth() + 1, 0);
      from_date = firstDateOfMonth(new Date(date));
      to_date = lastDateOfMonth(new Date(date));
    } else if (typeSent === TYPE[2]) {
      if (moment(date[0]).format() === moment(date[1]).format()) {
        const today = moment();
        const begginingOfCurrentWeek = today.startOf("week").format();
        const endOfWeek = today.endOf("week").format();
        from_date = begginingOfCurrentWeek;
        to_date = endOfWeek;
      } else {
        from_date = moment(date[0]).format();
        to_date = moment(date[1]).format();
      }
    } else if (typeSent === TYPE[3]) {
      from_date = date[0];
      to_date = date[0];
    } else if (typeSent === TYPE[5]) {
      from_date = moment(date).subtract("months", 3).startOf("month").format();
      to_date = moment().subtract("months", 1).endOf("month").format();
    } else {
      from_date = moment(date).subtract("months", 6).startOf("month").format();
      to_date = moment().subtract("months", 1).endOf("month").format();
    }
    return { from_date, to_date };
  }
  function getFormView(button_name) {
    if (button_name === "Month") {
      // console.log("month");
      return <p>Month</p>;
    } else if (button_name === "Year") {
      // console.log("Year");
      return <p>Year</p>;
    } else if (button_name === "Day") {
      // console.log("Day");
      return <p>Day</p>;
    } else if (button_name === "Week") {
      // console.log("Week");
      return <p>Week</p>;
    }
  }
  function setDate(dateObject, type) {
    if (dateObject) {
      const date = Array.isArray(dateObject)
        ? dateObject.map((date) => new Date(date).toISOString())
        : [new Date(dateObject).toISOString()];
      let { from_date, to_date } = getDateRange(date, type);
      data.current = {
        ...data.current,
        from_date: moment(from_date).format("YYYY-MM-DD"),
        to_date: moment(to_date).format("YYYY-MM-DD"),
      };
    }
  }
  function RecruiterValueSet(value) {
    if (!hasAccess) return;
    setRecruiter(value);
    // setData((prevData) => {
    //   return {
    //     ...prevData,
    //     recruiter_id: data.current._id,
    //   };
    // });
    data.current = {
      ...data.current,
      recruiter_id: value?._id,
    };
  }
  function validateData() {
    if (data.current.from_date === "") {
      alert.show(REPORTCALENDARALERTMESSAGE, {
        type: CommonString.error,
      });
    } else if (data.current.to_date === "") {
      alert.show(REPORTCALENDARALERTMESSAGE, {
        type: CommonString.error,
      });
    } else return true;
  }
  async function getData() {
    if (!validateData()) return;
    setLoading(true);
    const res = await reportController.getReport(data.current);
    if (res) {
      filterSubmitData(res.Total_Submit);
      setTotalSubmit(res.Total_Submit);
      setAddedApplicant(res.Total_Applicant_Added);
      const newAssignedJobData = res.Assigned_Job.map((ele) => {
        return {
          month: ele.month.substring(0, 3),
          count: ele.count,
        };
      });
      getYearInitalResponse(data.current);
      setJobCountData(newAssignedJobData);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  function reset() {
    if (hasAccess) {
      setRecruiter({
        name: "All",
        _id: "123",
        is_recruiter: 1,
      });
    }
    setType(TYPE[0]);
    date.current = new DateObject();
  }
  function onClickViewMore() {
    setIsRotate(!isRotate);
  }
  function TypeSetter(value) {
    setType(value);
    if (value === "Week") {
      setSelectedDates(defaultWeekDates);
    } else {
      setSelectedDates(date.current);
    }
    let { from_date, to_date } = getDateRange(date.current, value);
    const utc_from_date = moment.tz(from_date, "UTC");
    const formatted_from_utc_date = utc_from_date.format();
    const utc_to_date = moment.tz(to_date, "UTC").subtract("days", 1).format();
    data.current = {
      ...data.current,
      from_date: formatted_from_utc_date,
      to_date: utc_to_date,
    };
  }
  function setCustomDate(dateObject, name) {
    if (name === "from_date") {
      setFromDate(dateObject);
      data.current = {
        ...data.current,
        from_date: dateObject,
        to_date: toDate,
      };
    }
    setToDate(dateObject);
    data.current = {
      ...data.current,
      from_date: fromDate,
      to_date: dateObject,
    };
  }
  function downloadToXLSX() {
    //attendanceData
    let fileData = [
      {
        sheet: "Report",
        columns: [
          { label: "NAME", value: (row) => row.name },
          { label: "PREXELITE ID", value: (row) => row.prexelite_id },
          { label: "SUBMISSION", value: (row) => row.submission },
          // {
          //   label: "REJECTED BY CLIENT",
          //   value: (row) => row.rejected_by_client,
          // },
          { label: "INTERVIEW", value: (row) => row.interview },
          // { label: "INTERVIEW NO SHOW", value: (row) => row.interview_no_show },
          { label: "OFFER", value: (row) => row.offer },
          // {
          //   label: "DECLINED/BD BY CLIENT",
          //   value: (row) => row.declined_bd_by_client,
          // },
          // {
          //   label: "DECLINED/BD BY CONSULTANT",
          //   value: (row) => row.declined_bd_by_consultant,
          // },
          { label: "PLACEMENT", value: (row) => row.placement },
          // { label: "TERMINATED", value: (row) => row.terminated },
          // { label: "RESIGNED", value: (row) => row.resigned },
          { label: "PROJECT END", value: (row) => row.project_end },
        ],
        content: allRecruiterReportData.map((recruiter) => {
          return {
            name: recruiter.name,
            prexelite_id: recruiter.employee_id,
            submission: recruiter.SUBMISSION,
            // rejected_by_client: recruiter["REJECTED BY CLIENT"],
            interview: recruiter.INTERVIEW,
            // interview_no_show: recruiter["INTERVIEW NO SHOW"],
            offer: recruiter.OFFER,
            // declined_bd_by_client: recruiter["DECLINED/BD BY CLIENT"],
            // declined_bd_by_consultant: recruiter["DECLINED/BD BY CONSULTANT"],
            placement: recruiter.PLACEMENT,
            // terminated: recruiter.TERMINATED,
            // resigned: recruiter.RESIGNED,
            project_end: recruiter["PROJECT END"],
          };
        }),
      },
    ];
    let filename = "";
    if (type === TYPE[5] || type === TYPE[6]) {
      const Names = type.split(" ");
      Names.forEach((word) => word[0].toUpperCase());
      filename = Names.reduce(
        (accumulator, currentValue) => accumulator + " " + currentValue,
        Names[0]
      );
    } else {
      let from_date_name = dayjs(data.current.from_date).format("MMM YYYY");
      let to_date_name = dayjs(data.current.to_date).format("MMM YYYY");
      filename = from_date_name + " - " + to_date_name + " ";
    }
    let settings = {
      fileName: filename + "Recruiter Perfomance Report", // Name of the resulting spreadsheet
      extraLength: 3, // A bigger number means that columns will be wider
      writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
      RTL: false, // Display the columns from right-to-left (the default value is false)
    };
    // console.log(fileData);
    xlsx(fileData, settings);
  }
  function ClientValueSet(value) {
    setClient(value);
    data.current = {
      ...data.current,
      client_id: value?._id,
    };
  }
  function PartnerValueSet(value) {
    setPartner(value);
    data.current = {
      ...data.current,
      partner_id: value?._id,
    };
  }
  return (
    <>
      <TopFixedMenu>
        <TopMenu noDisplay={false} status={REPORT} />
      </TopFixedMenu>
      <CommonBaseModal
        size={"medium"}
        zIndex={5}
        children={getFormView(button_name)}
      />
      <ReportContainer>
        <Grid container columnSpacing={2}>
          <Grid item xs={2}>
            <SelectTextFields
              placeholder={"Recruiter"}
              disabled={!hasAccess}
              value={recruiter}
              onChange={(value) => RecruiterValueSet(value)}
              options={hasAccess ? temp_recruiter_list : [recruiter]}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item md={2}>
            <SelectTextFields
              placeholder={"Client"}
              value={client}
              onChange={(value) => ClientValueSet(value)}
              options={temp_client_data}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item md={2}>
            <SelectTextFields
              placeholder={"Partner"}
              value={partner}
              onChange={(value) => PartnerValueSet(value)}
              options={temp_partner_data}
              getOptionLabel={(option) => option.name}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectTextFields
              placeholder={"Duration"}
              value={type}
              onChange={(value) => {
                TypeSetter(value);
              }}
              options={TYPE}
              getOptionLabel={(option) => option}
            />
          </Grid>
          {type === TYPE[4] ? (
            <Grid item xs={2}>
              <FlexContainer>
                <DatePickerCalendar
                  onChange={(e, value) => {
                    setFromDate(value.validatedValue[0]);
                    setCustomDate(value.validatedValue[0], "from_date");
                  }}
                  value={fromDate}
                  render={<FormInput value={""} placeholder="From Date" />}
                />
                {/* </> */}
                {/* <Grid item xs={2}> */}
                <div style={{ marginLeft: 10 }}>
                  <DatePickerCalendar
                    onChange={(e, value) => {
                      setToDate(value.validatedValue[0]);
                      setCustomDate(value.validatedValue[0], "to_date");
                    }}
                    value={toDate}
                    render={
                      <FormInput
                        value={""}
                        // onChange={(e) => console.log(e.target.value)}
                        placeholder="To Date"
                      />
                    }
                  />
                </div>
              </FlexContainer>
            </Grid>
          ) : (
            <></>
          )}
          {type !== TYPE[4] && type !== TYPE[5] && type !== TYPE[6] ? (
            <Grid item xs={2}>
              <DatePickerCalendar
                // range={type === TYPE[3]}
                weekPicker={type === TYPE[2]}
                onlyMonthPicker={type === TYPE[1]}
                onlyYearPicker={type === TYPE[0]}
                onChange={(e, value) => setDate(e, type)}
                value={selectedDates}
                maxDate={new Date()}
                render={
                  <FormInput
                    value={""}
                    // onChange={(e) => console.log(e.target.value)}
                    placeholder="Select Date"
                  />
                }
              />
            </Grid>
          ) : (
            ""
          )}
          <Grid item md={2} style={{ marginTop: 5 }}>
            <FlexContainer>
              <CustomButton name="apply" onClick={() => getData()} />
              <OutlinedButtonContainer>
                <CustomButton name="clear" onClick={() => reset()} />
              </OutlinedButtonContainer>
            </FlexContainer>
          </Grid>
        </Grid>
        {loading ? (
          <></>
        ) : (
          <>
            <TotalCountCards
              loading={loading}
              jobCountData={jobCountData}
              data={totalSubmit}
              addedApplicant={addedApplicant}
            />
            <ReportTableContainer isVisible={hasAccess}>
              <CustomButton
                icon={<Icon icon="material-symbols:download" />}
                name="download report"
                onClick={() => downloadToXLSX()}
              />
              <ReportTable allReportData={allRecruiterReportData} />
            </ReportTableContainer>
          </>
        )}
        {loading ? <LoaderForTable /> : <></>}
      </ReportContainer>
    </>
  );
}
{
  /* <Grid container spacing={2}>
          <GirdHalf>
            <Grid container spacing={2}>
              <GirdHalf>
                <CardContainer>
                  <BarRoundedChartTitle>Applicant</BarRoundedChartTitle>
                  <BarRoundedChartCount>{addedApplicant}</BarRoundedChartCount>
                </CardContainer>
              </GirdHalf>
              <GirdHalf>
                <PieChartContainer>
                  <InnerCard $isRotate={isRotate}>
                    <FrontCard>
                      <CustomizedPieChart
                        data={statusData}
                        onClickViewMore={onClickViewMore}
                      />
                    </FrontCard>
                    <BackCard>
                      <PieChartLabel
                        data={totalSubmit}
                        onClickViewMore={onClickViewMore}
                      />
                    </BackCard>
                  </InnerCard>
                </PieChartContainer>
              </GirdHalf>
            </Grid>
          </GirdHalf>
          <GirdHalf>
            <GraphContainer>
              <CustomizedBarChart data={jobCountData} />
            </GraphContainer>
          </GirdHalf>
        </Grid> */
}
