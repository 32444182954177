import React, { useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import JobDataTable from "../DataTable/JobDataTable";
import { useSelector, useDispatch } from "react-redux";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import JobController from "../../controller/JobController";
import {
  changeInitialLoader,
  changeSearchText,
  getVisibility,
  onChangePageNumber,
  removeJObData,
} from "../../feature/store/ReduxStore";
import {
  ADD_JOBS,
  JOB,
  SEARCH_JOB,
  UPDATE_JOBS,
  VIEW_JOBS,
} from "../../Utils/enum";
import {
  changeClientListForFilter,
  changeIsJobStatusName,
  changeIsLoading,
  changeIsPartnerInAddJob,
  changeSearchjob,
  changeSelectedRecruiterId,
  removeSelectedFileName,
  removeTempSelectedFileName,
} from "../../feature/store/JobReduxStore";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { CommonStringForLocalStorage } from "../../Utils/CommonString";
import {
  changeButtonName,
  changeIsOpenModal,
  changeIsSearching,
} from "../../feature/store/ApplicantStore";
import CommonBaseModal from "../Modal/CommonBaseModal";
import AddJob from "../Modal/AddJob";
import JobViewDetails from "../ViewDetails/JobViewDetails";
import ConfirmationAlert from "../Modal/ConfirmationAlert";
import { confirmAlert } from "react-confirm-alert";
import ConfirmationAlertForAddPartner from "../Modal/ConfirmationAlertForAddPartner";
import { changeIsSearchLoading } from "../../feature/store/SubmissionReduxStore";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import ClientController from "../../controller/ClientController";

export default function JobsView() {
  var clientController = new ClientController();
  const jobPagenumber = useSelector((state) => state.counter.jobPagenumber);
  const searchJobPagenumber = useSelector(
    (state) => state.counter.searchJobPagenumber
  );
  const job_status_name = useSelector(
    (state) => state.JobReduxStore.job_status_name
  );
  const jobList = useSelector((state) => state.counter.jobData);
  const searchText = useSelector((state) => state.counter.searchText);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const jobController = new JobController();
  const dispatch = useDispatch();
  const partner_in_add_job = useSelector(
    (state) => state.JobReduxStore.partner_in_add_job
  );
  const selected_data = useSelector(
    (state) => state.JobReduxStore.selected_data
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );

  useEffect(() => {
    getClientData();
    dispatch(removeJObData([]));
    dispatch(onChangePageNumber(1));
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    dispatch(changeSearchjob(jobList));
    getData();
  }, []);

  useEffect(() => {
    dispatch(removeJObData([]));
    dispatch(onChangePageNumber(1));
    var isVisible = onCheckLevelAccessibility();
    if (!isVisible) {
      var user = JSON.parse(
        localStorage.getItem(CommonStringForLocalStorage.user)
      ).user._id;
      dispatch(changeSelectedRecruiterId(user));
      return;
    } else {
      dispatch(changeSelectedRecruiterId("123"));
    }
    dispatch(changeSelectedRecruiterId("123"));
  }, []);
  async function getClientData() {
    var res = await clientController.getClients();
    var filteredData = res.filter((data) => data._is_active === 0);
    dispatch(
      changeClientListForFilter([{ name: "All", _id: "123" }, ...filteredData])
    );
  }
  async function getData() {
    if (jobPagenumber === 1) {
      dispatch(changeInitialLoader(true));
    } else {
      dispatch(changeIsLoading(true));
    }
    var sendData = {
      status: job_status_name,
      order: "ASC",
      recruiter_id: "123",
      partner_id: "123",
      client_id: "123",
    };
    var data = await jobController.getJobsByStatus(sendData, jobPagenumber);
    dispatch(changeIsJobStatusName(false));
    if (jobPagenumber === 1) {
      dispatch(changeInitialLoader(false));
    } else {
      dispatch(changeIsLoading(false));
    }
  }
  async function onSearch(e) {
    dispatch(changeIsSearchLoading(true));
    dispatch(changeIsSearching(true));
    dispatch(changeSearchText(searchText));
    var sendData = {
      search_query: searchText.trim(),
    };
    var res = await jobController.searchJob(sendData, searchJobPagenumber);
    dispatch(changeSearchjob(res.data));
    dispatch(changeIsSearchLoading(false));
  }
  function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    if (e.target.value === "") {
      dispatch(changeIsSearching(false));
    }
    dispatch(changeSearchText(e.target.value));
    if (searchText.length === 0) {
      dispatch(changeSearchjob(jobList));
    }
  }
  function getFormView(button_name) {
    if (button_name === UPDATE_JOBS) {
      return <AddJob data={selected_data} />;
    } else if (button_name === VIEW_JOBS) {
      return <JobViewDetails data={selected_data} />;
    }
    if (button_name === "Add Partner" && !partner_in_add_job) {
      return <JobViewDetails data={selected_data} />;
    }
    if (button_name === "Add Partner") {
      return (
        <AddJob handleClose={() => handleCloseJob()} data={selected_data} />
      );
    }
    if (button_name === "Clone Job") {
      return (
        <AddJob handleClose={() => handleCloseJob()} data={selected_data} />
      );
    }
    return <AddJob data={null} />;
  }
  function handleCloseJob() {
    if (button_name === VIEW_JOBS || button_name === UPDATE_JOBS) {
      dispatch(changeIsOpenModal(false));
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmationAlertForAddPartner
              onClose={onClose}
              onDelete={() => {
                onViewModal();
                onClose();
                dispatch(changeIsPartnerInAddJob(true));
              }}
            />
          );
        },
      });
    }
  }
  function onCloseJob() {
    if (button_name === VIEW_JOBS || button_name === UPDATE_JOBS) {
      dispatch(changeIsOpenModal(false));
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmationAlert
              onClose={onClose}
              onDelete={() => {
                dispatch(removeSelectedFileName([]));
                dispatch(removeTempSelectedFileName([]));
                dispatch(changeIsOpenModal(true));
                onClose();
                dispatch(changeIsOpenModal(false));
              }}
            />
          );
        },
      });
    }
  }
  function onViewModal() {
    dispatch(changeButtonName("Add Partner"));
  }
  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      <CommonBaseModal
        handleClose={() => {
          onCloseJob();
        }}
        zIndex={5}
        children={getFormView(button_name)}
      />
      <TopFixedMenu>
        <TopMenu
          noDisplay={true}
          placeholder={SEARCH_JOB}
          onChange={(e) => onChangeSearchText(e)}
          onClickSearch={onSearch}
          buttonName={JOB}
          viewButton={access_data_by_user_id.includes("job_create")}
          onClickAddButton={() => {
            dispatch(changeIsOpenModal(true));
            dispatch(changeButtonName(ADD_JOBS));
          }}
        />
      </TopFixedMenu>
      <MainScreen>
        <JobDataTable onRefresh={() => getData()} />
      </MainScreen>
    </Container>
  );
}
