import { useDispatch, useSelector } from "react-redux";
import ReportProvider from "../services/provider/ReportProvider";

export default class ReportController {
  constructor() {
    this.reportProvider = new ReportProvider();
    this.selected_report_type = new useSelector(
      (state) => state.counter.selected_report_type
    );
    this.dispatch = new useDispatch();
  }
  getMonthJobReport = async (data) => {
    var res = await this.reportProvider.getMonthJobReport(data);
    return res;
  };
  getYearJobReport = async (data) => {
    var res = await this.reportProvider.getYearJobReport(data);
    return res;
  };
  getMonthSubmissionReport = async (data) => {
    var res = await this.reportProvider.getMonthSubmissionReport(data);
    return res;
  };
  getYearSubmissionReport = async (data) => {
    var res = await this.reportProvider.getYearSubmissionReport(data);
    return res;
  };
  onChangeGetSubmissionReport = async (data) => {
    if (data === "Year") {
      console.log("Year data");
      return;
    } else if (data === "Month") {
      console.log("Month data");
      return;
    } else if (data === "Week") {
      console.log("Week data");
      return;
    }
    console.log("year");
    return;
  };
  getReport = async (data) => {
    var res = await this.reportProvider.getReport(data);
    if (res.status === 200) return res.data;
    else {
      console.error("error while getting data from report api ");
      return;
    }
  };
  getYearReport = async (data) => {
    let res = await this.reportProvider.getYearReport(data);
    if (res.status === 200) return res.data;
    else {
      console.error("error while getting data from year report api ");
      return;
    }
  };
  getCallReport = async () => {
    let res = await this.reportProvider.getCallReport();
    if (res.status === 200) return res.data.data;
    else {
      console.error("error while getting data from call report api ");
      return;
    }
  };
  addCallReport = async (data) => {
    let res = await this.reportProvider.addCallReport(data);
    if (res.status === 200) return res?.data?.data[0];
    else {
      console.error("error while adding data from call report api ");
      return;
    }
  };
  editCallReport = async (data) => {
    let res = await this.reportProvider.editCallReport(data);
    if (res.status === 200) return res?.data?.data[0];
    else {
      console.error("error while updating data from call report api ");
      return;
    }
  };
  deleteCallReport = async (data) => {
    let res = await this.reportProvider.deleteCallReport(data);
    if (res.status === 200) return res;
    else {
      console.error("error while deleting data from call report api ");
      return;
    }
  };
  getCallReportByUserIdAndDate = async (data) => {
    let res = await this.reportProvider.getCallReportByUserIdAndDate(data);
    if (res.status === 200) return res.data;
    else {
      console.error("error while adding data from call report api ");
      return;
    }
  };
}
