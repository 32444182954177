import React, { useEffect } from "react";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import {
  DATE_FORMATE,
  JOB_TH,
  JobStatusArray,
  dueDateShortBy,
} from "../../../Utils/constant";
import moment from "moment";
import { DASHBOARD, UPDATE_JOBS, VIEW_JOBS } from "../../../Utils/enum";
import {
  getAccessForJob,
  getClientName,
  getRecruiters,
  jobStatusColor,
  onCheckForSeniorRecruiter,
  onCheckLevelAccessibility,
} from "../../../Utils/utilitiFunction";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedData } from "../../../feature/store/JobReduxStore";
import {
  CustomTableContainer,
  TableTh,
  TableTd,
  Table,
  TableRow,
  TableTbody,
  JobFirstColumn,
} from "../../../style/table.styled";
import {
  changeButtonName,
  changeIsOpenModal,
} from "../../../feature/store/ApplicantStore";
import {
  onChangePageNumber,
  removeJObData,
} from "../../../feature/store/ReduxStore";
import SearchLoader from "../../CommonComponet/SearchLoader";
import {
  CopyTextMainContainer,
  TableNoDataContainer,
} from "../../../style/common.style";
import NoData from "../../../assets/images/nodata.png";
import {
  ButtonForChangePage,
  ButtonForChangePageContainer,
} from "../../../style/button.style";
import { ButtonName } from "../../../Utils/CommonString";
import FilterForJob from "../../jobs/FilterForJob";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";
import CopyTextComponent from "../../CommonComponet/CopyTextComponent";

export default function JobTable(props) {
  const dispatch = useDispatch();
  const job_status_name = useSelector(
    (state) => state.JobReduxStore.job_status_name
  );
  const table_list = useSelector((state) => state.JobReduxStore.table_list);
  // const client_list = useSelector(
  //   (state) => state.JobReduxStore.client_list_for_filter
  // );
  const is_loading = useSelector((state) => state.JobReduxStore.is_loading);
  const menuStatus = useSelector((state) => state.counter.menuStatus);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const filter_data = useSelector((state) => state.JobReduxStore.filter_data);
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const partners = useSelector(
    (state) => state.JobReduxStore.temp_partner_list
  );
  console.log(
    "access_data_by_user_id",
    access_data_by_user_id.includes("job_update")
  );

  useEffect(() => {
    dispatch(removeJObData([]));
    dispatch(onChangePageNumber(1));
  }, [job_status_name]);

  function getDate(data) {
    if (data.due_date === null || data.due_date === undefined) {
      if (
        data.job_detail.due_date === "" ||
        data.job_detail.due_date === null
      ) {
        return "open";
      }
      return moment(data.job_detail.due_date).format(DATE_FORMATE);
    }
    return moment(data.due_date).format(DATE_FORMATE);
  }
  function onViewDetail(data, name) {
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(name));
    dispatch(changeSelectedData(data));
  }

  return (
    <>
      <CustomTableContainer
        length={props.jobList.length}
        height={props.height}
        // onScroll={(e) => handleScroll(e)}
      >
        <Table
          style={{
            marginBottom: menuStatus === DASHBOARD ? "40px" : "inherit",
          }}
        >
          <TableTbody>
            <TableRow>
              <TableTh
                width="10%"
                isVisible={!table_list.includes("status")}
                align={JOB_TH[0].align}
              >
                <FilterForJob
                  selectedValue={filter_data.status}
                  is_active={filter_data.status !== "ALL"}
                  list={JobStatusArray}
                  name={JOB_TH[0].name}
                />
              </TableTh>
              <TableTh width="9%" isVisible={!table_list.includes("due_date")}>
                <FilterForJob
                  selectedValue={filter_data.order}
                  // is_active={filter_data.order !== "ASC"}
                  list={dueDateShortBy}
                  name={JOB_TH[1].name}
                />
              </TableTh>
              <TableTh
                width="21%"
                isVisible={!table_list.includes("job_title")}
              >
                {JOB_TH[2].name}
              </TableTh>
              <TableTh width="10%" isVisible={!table_list.includes("job_id")}>
                {JOB_TH[3].name}
              </TableTh>
              <TableTh width="10%" isVisible={!table_list.includes("client")}>
                {JOB_TH[4].name}
                {/* <FilterForJob list={client_list} name={JOB_TH[4].name} /> */}
              </TableTh>
              <TableTh
                width="10%"
                isVisible={!table_list.includes("max_sub")}
                align={JOB_TH[0].align}
              >
                {JOB_TH[5].name}
              </TableTh>
              <TableTh
                width="10%"
                isVisible={!table_list.includes("sub_made")}
                align={JOB_TH[0].align}
              >
                {JOB_TH[6].name}
              </TableTh>
              <TableTh width="10%" isVisible={!table_list.includes("partner")}>
                <FilterForJob
                  selectedValue={filter_data.partner_id}
                  is_active={filter_data.partner_id !== "123"}
                  list={partners}
                  name={JOB_TH[7].name}
                />
              </TableTh>
              <TableTh
                width="10%"
                isVisible={!table_list.includes("recruiter")}
              >
                <FilterForJob
                  selectedValue={filter_data.recruiter_id}
                  is_active={filter_data.recruiter_id !== "123"}
                  list={temp_recruiter_list}
                  name={JOB_TH[8].name}
                />
              </TableTh>
              <TableTh width="10%"></TableTh>
            </TableRow>
            {props.jobList.map((data, index) => {
              return (
                <TableRow
                  key={index + 1}
                  onDoubleClick={() => onViewDetail(data, VIEW_JOBS)}
                  onClick={() =>
                    window.innerWidth < 500
                      ? onViewDetail(data, VIEW_JOBS)
                      : null
                  }
                >
                  <TableTd
                    isVisible={
                      !table_list.includes("status") || props.isDashboard
                    }
                  >
                    <JobFirstColumn
                      style={{
                        color: jobStatusColor[data.status].color,
                        background: jobStatusColor[data.status].backgroundColor,
                      }}
                    >
                      {data.status}
                    </JobFirstColumn>
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("due_date")}>
                    {getDate(data)}
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("job_title")}>
                    <CopyTextMainContainer>
                      <CustomizedTooltip
                        name={data.job_detail.position_title}
                        title={data.job_detail.position_title}
                      />
                      <CopyTextComponent
                        value={data.job_detail.position_title}
                        id={"job_title"}
                      />
                    </CopyTextMainContainer>
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("job_id")}>
                    {data.job_detail.client_position_id === "" ? (
                      "NA"
                    ) : (
                      <CopyTextMainContainer>
                        <CustomizedTooltip
                          name={data.job_detail.client_position_id}
                          title={data.job_detail.client_position_id}
                        />
                        <CopyTextComponent
                          value={data.job_detail.client_position_id}
                          id={"job_id"}
                        />
                      </CopyTextMainContainer>
                    )}
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("client")}>
                    <CustomizedTooltip
                      name={getClientName(data.job_detail.client.name)}
                      title={getClientName(data.job_detail.client.name)}
                    />
                  </TableTd>
                  <TableTd
                    isVisible={!table_list.includes("max_sub")}
                    isCenter={true}
                  >
                    {data.max_submission === "" ? "NA" : data.max_submission}
                  </TableTd>
                  <TableTd
                    isVisible={!table_list.includes("sub_made")}
                    isCenter={true}
                  >
                    {data.no_of_submission}
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("partner")}>
                    <CustomizedTooltip
                      name={getClientName(data.partner.name)}
                      title={getClientName(data.partner.name)}
                    />
                    {/* {data.partner !== undefined
                      ? getClientName(data.partner.name)
                      : "123"} */}
                  </TableTd>
                  <TableTd isVisible={!table_list.includes("recruiter")}>
                    {/* {getRecruiters(data)} */}
                    {data?.recruiter?.name.split(" ")[0] ?? "Open"}
                  </TableTd>
                  <TableTd
                    isVisible={props.isDashboard}
                    onClick={(event) => event.stopPropagation()}
                  >
                    {access_data_by_user_id.includes("job_update") ||
                    access_data_by_user_id.includes("job_delete") ? (
                      <VerticalMenu
                        data={getAccessForJob(access_data_by_user_id)}
                        onClone={() => onViewDetail(data, "Clone Job")}
                        onEdit={() => onViewDetail(data, UPDATE_JOBS)}
                        onDelete={() => props.onDelete(data)}
                      />
                    ) : (
                      ""
                    )}
                  </TableTd>
                </TableRow>
              );
            })}
          </TableTbody>
        </Table>
        {props.jobList.length !== 0 || is_loading || initial_loader ? (
          <></>
        ) : (
          <TableNoDataContainer>
            <img src={NoData} alt="" />
          </TableNoDataContainer>
        )}
        {props.jobList.length % 20 !== 0 ||
        props.jobList.length === 0 ||
        is_loading ||
        initial_loader ? (
          <></>
        ) : (
          <ButtonForChangePageContainer style={{ marginTop: 5 }}>
            <ButtonForChangePage onClick={() => props.onRefresh()}>
              {ButtonName.load_more}
            </ButtonForChangePage>
          </ButtonForChangePageContainer>
        )}
      </CustomTableContainer>
      {is_loading && props.jobList.length !== 0 ? <SearchLoader /> : <></>}
    </>
  );
}
