import React, { useEffect, useState } from "react";
import { Menu } from "@mui/material";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  FilterIconInTable,
  FlexContainerForFilter,
  MenuItemForFilter,
} from "../../style/common.style";
import { JOB_TH } from "../../Utils/constant";
import {
  changeFilterData,
  changeIsLoading,
  changeJobStatusName,
  changePartnerDataForFilter,
  changeRecruiterDataForFilter,
} from "../../feature/store/JobReduxStore";
import { useDispatch, useSelector } from "react-redux";
import JobController from "../../controller/JobController";
import { removeJObData } from "../../feature/store/ReduxStore";
import { getCapitalizeText, getClientName } from "../../Utils/utilitiFunction";
import { REPORT } from "../../Utils/enum";
const ITEM_HEIGHT = 48;

export default function FilterForJob(props) {
  const dispatch = useDispatch();
  const jobController = new JobController();
  const filter_data = useSelector((state) => state.JobReduxStore.filter_data);
  const menuStatus = useSelector((state) => state.counter.menuStatus);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedName, setSelectedName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  // const [searchText, setSearchText] = useState("");
  var list_with_id =
    props.name === JOB_TH[7].name ||
    props.name === JOB_TH[8].name ||
    props.name === JOB_TH[4].name;
  const open = Boolean(anchorEl);
  useEffect(() => {
    onPrefilledData();
  }, []);

  function onPrefilledData() {
    if (props.name === JOB_TH[7].name) {
      setSelectedName("All");
    }
    if (props.name === JOB_TH[8].name) {
      setSelectedName("All");
    }
    if (props.name === JOB_TH[0].name) {
      setSelectedName(filter_data?.status);
    }
    if (props.name === JOB_TH[1].name) {
      setSelectedName(filter_data?.order);
    }
    if (props.name === JOB_TH[4].name) {
      setSelectedName("All");
    }
  }

  async function onClick(data) {
    if (menuStatus === REPORT) {
      props.getSelectedName(data);
    }
    dispatch(removeJObData([]));
    setAnchorEl(null);
    dispatch(changeIsLoading(true));
    if (props.name === JOB_TH[7].name) {
      setSelectedName(getClientName(data.name));
      dispatch(changePartnerDataForFilter(data));
      dispatch(changeFilterData({ ...filter_data, partner_id: data._id }));
      let res = await jobController.getJobsByStatus(
        { ...filter_data, partner_id: data._id },
        1
      );
      dispatch(changeIsLoading(false));
      return;
    }
    if (props.name === JOB_TH[8].name) {
      setSelectedName(data.name);
      dispatch(changeRecruiterDataForFilter(data));
      dispatch(changeFilterData({ ...filter_data, recruiter_id: data._id }));
      let res = await jobController.getJobsByStatus(
        { ...filter_data, recruiter_id: data._id },
        1
      );
      dispatch(changeIsLoading(false));
      return;
    }
    if (props.name === JOB_TH[0].name) {
      setSelectedName(data);
      dispatch(changeJobStatusName(data));
      dispatch(changeFilterData({ ...filter_data, status: data }));
      let res = await jobController.getJobsByStatus(
        { ...filter_data, status: data },
        1
      );
      dispatch(changeIsLoading(false));
      return;
    }
    if (props.name === JOB_TH[1].name) {
      setSelectedName(data);
      dispatch(changeFilterData({ ...filter_data, order: data }));
      let res = await jobController.getJobsByStatus(
        { ...filter_data, order: data },
        1
      );
      dispatch(changeIsLoading(false));
    }
    if (props.name === JOB_TH[4].name) {
      setSelectedName(data.name);
      dispatch(changeFilterData({ ...filter_data, client_id: data._id }));
      let res = await jobController.getJobsByStatus(
        { ...filter_data, client_id: data?._id },
        1
      );
      dispatch(changeIsLoading(false));
    }
  }
  function onSearch(search_text) {
    if (list_with_id) {
      let search_data = props?.list.filter((data) =>
        data.name.includes(getCapitalizeText(search_text))
      );
      setSearchResult(search_data);
      return;
    }
  }

  return (
    <div>
      <FlexContainerForFilter
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <div>
          {props.name}
          {/* <span>({selectedName})</span> */}
        </div>
        <FilterIconInTable
          is_active={props.is_active}
          icon={CommonIcon.filter}
        />
      </FlexContainerForFilter>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => {
          setAnchorEl(null);
          setSearchResult([]);
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            // padding: "0 10px",
          },
        }}
      >
        <div style={{ padding: "0px 10px", marginBottom: 5 }}>
          {list_with_id ? (
            <input
              style={{ width: "100%" }}
              type="text"
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Search.."
              name="search2"
            />
          ) : (
            <></>
          )}
        </div>
        {(searchResult.length === 0 ? props?.list : searchResult)?.map(
          (data) => (
            <MenuItemForFilter
              activeStatus={
                props.selectedValue === (list_with_id ? data._id : data)
              }
              onBlur={() => onClick(data)}
              onClick={() => onClick(data)}
            >
              {list_with_id
                ? getCapitalizeText(data.name)
                : getCapitalizeText(data)}
            </MenuItemForFilter>
          )
        )}
      </Menu>
    </div>
  );
}
