import React, { useEffect, useState } from "react";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  FilterIconInTable,
  FlexContainerForFilter,
  MenuItemForFilter,
} from "../../style/common.style";
import { Menu } from "@mui/material";
import {
  onChangeFilterStatusObject,
  onChangeSubmissionPageNumber,
  onRemoveSubmissionData,
} from "../../feature/store/ReduxStore";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterData } from "../../feature/store/SubmissionReduxStore";
import { getCapitalizeText } from "../../Utils/utilitiFunction";
const ITEM_HEIGHT = 48;

export default function SubmissionFilter(props) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [selectedName, setSelectedName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const open = Boolean(anchorEl);
  const filter_data = useSelector(
    (state) => state.SubmissionReduxStore.filter_data
  );

  var list_with_id =
    props.name === "Recruiter Name" ||
    props.name === "Partner" ||
    props.name === "Client";

  useEffect(() => {
    dispatch(
      changeFilterData({
        status: "ALL",
        recruiter_id: "123",
        client_id: "123",
        partner_id: "123",
      })
    );
  }, []);

  async function onClick(data) {
    // var sendData = {
    //   status: status.toUpperCase(),
    //   recruiter_id: recruiter?._id,
    // };
    setAnchorEl(null);
    if (props.name === "Recruiter Name") {
      // setSelectedName(data.name);
      dispatch(changeFilterData({ ...filter_data, recruiter_id: data._id }));
      dispatch(onChangeSubmissionPageNumber(1));
      dispatch(onRemoveSubmissionData([]));
      // dispatch(
      //   onChangeFilterStatusObject({ ...filter_data, recruiter_id: data._id })
      // );
      return;
    } else if (props.name === "Partner") {
      // setSelectedName(data.name);
      dispatch(changeFilterData({ ...filter_data, partner_id: data._id }));
      dispatch(onChangeSubmissionPageNumber(1));
      dispatch(onRemoveSubmissionData([]));
      // dispatch(
      //   onChangeFilterStatusObject({ ...filter_data, partner_id: data._id })
      // );
      return;
    } else if (props.name === "Client") {
      // setSelectedName(data.name);
      dispatch(changeFilterData({ ...filter_data, client_id: data._id }));
      dispatch(onChangeSubmissionPageNumber(1));
      dispatch(onRemoveSubmissionData([]));
      // dispatch(
      //   onChangeFilterStatusObject({ ...filter_data, client_id: data._id })
      // );
      return;
    }
    // setSelectedName(data);
    dispatch(onChangeSubmissionPageNumber(1));
    dispatch(onRemoveSubmissionData([]));
    dispatch(changeFilterData({ ...filter_data, status: data }));
    // dispatch(onChangeFilterStatusObject({ ...filter_data, status: data }));
  }
  function onSearch(search_text) {
    if (list_with_id) {
      let search_data = props?.list.filter((data) =>
        data.name.includes(getCapitalizeText(search_text))
      );
      setSearchResult(search_data);
      return;
    }
  }

  return (
    <>
      <FlexContainerForFilter
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setSearchResult([]);
        }}
      >
        <div>
          {props.name}
          {/* <span>({selectedName})</span> */}
        </div>
        <FilterIconInTable
          is_active={props.is_active}
          icon={CommonIcon.filter}
        />
      </FlexContainerForFilter>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            marginTop: 5,
          },
        }}
      >
        {list_with_id ? (
          <div style={{ padding: "0px 10px", marginBottom: 5 }}>
            <input
              type="text"
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Search.."
              name="search2"
            />
          </div>
        ) : (
          <></>
        )}
        {(searchResult.length === 0 ? props?.list : searchResult).map(
          (data) => (
            <MenuItemForFilter
              activeStatus={
                props.selectedValue === (list_with_id ? data._id : data)
              }
              onBlur={() => onClick(data)}
              onClick={() => onClick(data)}
            >
              {list_with_id ? data.name : data}
            </MenuItemForFilter>
          )
        )}
      </Menu>
    </>
  );
}
