import React, { useState } from "react";
import { Table } from "react-bootstrap";
import {
  CustomTableContainer,
  DashboardTableContainer,
  TableName,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../../style/table.styled";
import { DATE_FORMATE, SUBMISSION_TH } from "../../../Utils/constant";
import { CommonColor } from "../../../Utils/color";
import SubmissionStatus from "../../CommonComponet/IntwerviewDetail/SubmissionStatus";
import { useDispatch, useSelector } from "react-redux";
import { CommonString } from "../../../Utils/CommonString";
import moment from "moment";
import BaseModal from "../../Modal/BaseModal";
import { CALL_TRACKER, VIEW_CALL_TRACKER } from "../../../Utils/enum";
import Tracker from "./Tracker";
import { changeSubmissionData } from "../../../feature/store/SubmissionReduxStore";
import { getClientName } from "../../../Utils/utilitiFunction";

export default function TrackerTable() {
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [submission_data, setsubmission_data] = useState();
  const [isLoading, setisLoading] = useState(false);
  const submission_data_for_dashboard = useSelector(
    (state) => state.SubmissionReduxStore.submission_data_for_dashboard
  );
  const selectedSubmissionData = useSelector(
    (state) => state.SubmissionReduxStore.selectedSubmissionData
  );
  function onViewModal(modalSt, data) {
    setsubmission_data(data);
    setisOpenModal(true);
    setModalStatus(modalSt);
    dispatch(changeSubmissionData(data));
  }

  return submission_data_for_dashboard.length === 0 ? (
    <></>
  ) : (
    <>
      <BaseModal
        isFaq={true}
        isLoading={isLoading}
        mystyle={{ width: window.innerWidth > 500 ? "50%" : "90%" }}
        open={isopenModal}
        formTitle={CALL_TRACKER}
        handleClose={() => setisOpenModal(!isopenModal)}
        editClose={() => onViewModal(VIEW_CALL_TRACKER, selectedSubmissionData)}
      >
        <Tracker
          submission_id={submission_data?.submissionV2?._id}
          isLoading={(e) => setisLoading(e)}
          submission_data={submission_data}
          selectedData={selectedSubmissionData}
          handleClose={() => setisOpenModal(!isopenModal)}
          modalStatus={modalStatus}
        />
      </BaseModal>
      <DashboardTableContainer>
        <CustomTableContainer height="auto">
          <TableName>Consultant Follow Up</TableName>
          <Table>
            <TableTbody>
              <TableRow style={{ background: CommonColor.primary_color }}>
                <TableTh textAlignLeft="left"> Name</TableTh>
                <TableTh>Title</TableTh>
                <TableTh>ID</TableTh>
                <TableTh>Client</TableTh>
                <TableTh>Partner</TableTh>
                <TableTh style={{ whiteSpace: "nowrap" }}>Updated At</TableTh>
                <TableTh textAlignLeft="left">Status</TableTh>
              </TableRow>
              {submission_data_for_dashboard.map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableTd
                      textAlignLeft="left"
                      ListLength={SUBMISSION_TH.length}
                    >
                      {data?.submissionV2?.full_name}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {data?.submissionV2?.parentJob?.position_title}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {data?.submissionV2?.parentJob?.client_position_id ??
                        CommonString.na}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {getClientName(
                        data?.submissionV2?.parentJob?.client?.name
                      )}
                    </TableTd>
                    <TableTd ListLength={SUBMISSION_TH.length}>
                      {getClientName(data?.submissionV2?.partner?.name)}
                    </TableTd>
                    <TableTd
                      style={{ width: 90 }}
                      ListLength={SUBMISSION_TH.length}
                    >
                      {moment(data?.updated_at).format(DATE_FORMATE)}
                    </TableTd>
                    <TableTd
                      textAlignLeft="left"
                      ListLength={SUBMISSION_TH.length}
                    >
                      <SubmissionStatus
                        data={data}
                        onClick={() => onViewModal(CALL_TRACKER, data)}
                      />
                    </TableTd>
                  </TableRow>
                );
              })}
            </TableTbody>
          </Table>
        </CustomTableContainer>
      </DashboardTableContainer>
    </>
  );
}

// {
//     "_id": "046d5a20-ba41-11ee-bee9-8dba43764f4a",
//     "submission_id": "1114f740-ba2f-11ee-bee9-8dba43764f4a",
//     "call_1": "2024-01-29T00:00:00.000Z",
//     "note_1": null,
//     "call_2": "2024-02-07T00:00:00.000Z",
//     "note_2": null,
//     "call_3": "2024-02-19T00:00:00.000Z",
//     "note_3": null,
//     "call_1_status": "PENDING",
//     "call_2_status": "PENDING",
//     "call_3_status": "PENDING",
//     "is_deleted": false,
//     "_created_at": "2024-01-23 22:44:50",
//     "updated_at": "2024-01-23 22:44:50",
//     "submissionV2": {
//         "_id": "1114f740-ba2f-11ee-bee9-8dba43764f4a",
//         "parent_job_id": "c40b2f30-b4bd-11ee-a6f3-9f7671aa59e8",
//         "child_job_id": "c40f4de0-b4bd-11ee-a6f3-9f7671aa59e8",
//         "recruiter_id": "0b6ff2d0-4183-11ec-b835-abec3120c7c3",
//         "payrate": "96",
//         "tax_term": "W2 without Benifits",
//         "applicant_id": "063d3d50-ba2f-11ee-bee9-8dba43764f4a",
//         "partner_id": "2e8760c0-71a5-11ed-87fc-83274ca2bbc1",
//         "status": "INTERVIEW",
//         "_is_deleted": "0",
//         "client_id": "5e2cccf0-74f4-11ed-9672-d7fdc52cbd71",
//         "full_name": "Anup Paudel ",
//         "_created_at": "2024-01-23T21:36:20+01:00",
//         "_updated_at": "2024-01-29 23:44:04",
//         "recruiter": {
//             "name": "Abrar Shaikh"
//         },
//         "parentJob": {
//             "position_title": "A211: Gentax Developer"
//         }
//     }
// }
