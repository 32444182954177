import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import CustomButton from "../CommonComponet/Button";
import { formatPhoneNumber, ValidateEmail } from "../../services/Validate";
import { FormButton } from "../../style/button.style";
import CustomSwitch from "../CommonComponet/CustomSwitch";
import { SwitchWithTitle } from "../../style/common.style";

export default function AddPartner(props) {
  const [partnerName, setPartnerName] = useState();
  const [partnerID, setPartnerID] = useState();
  const [email, setEmail] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [POCName, setPOCName] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    onPrefilledData();
  }, []);
  function onPrefilledData() {
    if ((props.isUpdate || props.isView) && props.data != null) {
      setPartnerID(props.data.partner_id);
      setPartnerName(props.data.name);
      setEmail(props.data.email);
      setPhoneNo(formatPhoneNumber(props.data.phone));
      setPOCName(props.data.poc_name);
      setIsActive(props.data.is_active === 1 ? true : false);
    }
  }
  function onAddPartner() {
    if (!validate()) {
      return;
    }
    var phoneNumber =
      phoneNo !== undefined ? phoneNo.replace(/[^\d\+]/g, "") : "";
    var data = {
      partner_id: partnerID,
      name: partnerName,
      email: email,
      phone: phoneNumber,
      working_percentage: "0",
      margin_percentage: "0",
      poc_name: POCName,
      is_active: isActive ? 1 : 0,
    };
    props.onSubmitData(data);
  }
  function validate() {
    if (partnerID === null || partnerID === undefined) {
      setERROR_NAME("PARTNER_ID");
      return false;
    }
    if (partnerName === null || partnerName === undefined) {
      setERROR_NAME("PARTNER_NAME");
      return false;
    }
    if (email === null || !ValidateEmail(email) || email === undefined) {
      setERROR_NAME("EMAIL");
      return false;
    }
    // if (phoneNo === null || !ValidatePhone(phoneNo) || phoneNo === undefined) {
    //   setERROR_NAME("PHONE_NUMBER");
    //   return false;
    // }
    setERROR_NAME("");
    return true;
  }
  const handleInputPhone = (e) => {
    // storeDataInLocalStorage(e.target.value);
    setERROR_NAME("");
    setPhoneNo(formatPhoneNumber(e.target.value));
  };

  return (
    <>
      <FormInput
        error={ERROR_NAME === "PARTNER_ID" ? "fill" : "" > 0}
        value={partnerID || ""}
        onChange={(e) => {
          setPartnerID(e.target.value);
          setERROR_NAME("");
        }}
        placeholder="Partner ID *"
      />
      <FormInput
        error={ERROR_NAME === "PARTNER_NAME" ? "fill" : "" > 0}
        value={partnerName || ""}
        onChange={(e) => {
          setPartnerName(e.target.value);
          setERROR_NAME("");
        }}
        placeholder="Partner Name *"
      />
      <FormInput
        value={POCName || ""}
        onChange={(e) => {
          setPOCName(e.target.value);
        }}
        placeholder="POC Name *"
      />
      <FormInput
        error={ERROR_NAME === "EMAIL" ? "fill" : "" > 0}
        value={email || ""}
        onChange={(e) => {
          setEmail(e.target.value);
          setERROR_NAME("");
        }}
        placeholder="Email *"
      />
      <FormInput
        value={phoneNo || ""}
        onkeydown={(e) => formatPhoneNumber(e)}
        onChange={(e) => handleInputPhone(e)}
        placeholder="Phone No. *"
      />
      <SwitchWithTitle>
        {"Active partner\xa0\xa0\xa0:\xa0\xa0\xa0"}
        <CustomSwitch
          checked={isActive}
          onChange={(e) => setIsActive(e.target.checked)}
        />
      </SwitchWithTitle>
      <FormButton>
        <CustomButton
          onClick={onAddPartner}
          name={props.isUpdate ? "Update" : "Add"}
        />
      </FormButton>
    </>
  );
}
