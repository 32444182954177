import React, { useEffect, useState } from "react";
import RowText from "./RowText";
import { SubmissionTitle } from "../../../style/detail.style";
import {
  CommonString,
  CommonStringForApplicant,
  CommonTitle,
} from "../../../Utils/CommonString";
import RowTextHtml from "./RowTextHtml";
import {
  ApplicantViewDataContainer,
  DocContainer,
} from "../../../style/applicant.style";
import { CommonColor } from "../../../Utils/color";
import { formatPhoneNumber } from "../../../Utils/utilitiFunction";
import { Grid } from "@mui/material";
import { SubmissionController } from "../../../controller/SubmissionController";
import {
  Table,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../../style/table.styled";
import { submission_table_in_applicant } from "../../../Utils/mockData";
import moment from "moment";
import { DATE_FORMATE } from "../../../Utils/constant";
import { useSelector } from "react-redux";
import ApplicantDocument from "../../Applicant/ApplicantDocument";
import { formatName } from "../../../Utils/validation";
import { VIEW_SUBMISSION } from "../../../Utils/enum";

export default function ApplicantData(props) {
  const submissionController = new SubmissionController();
  // const [open, setOpen] = useState(false);
  const [submissionData, setSubmissionData] = useState([]);
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  useEffect(() => {
    getSubmissionData();
  }, []);
  async function getSubmissionData() {
    var data = {
      applicant_id: applicant._id,
    };
    var res = await submissionController.getSubmissionByApplicant(data);
    setSubmissionData(res.data.data);
  }
  function getCityName(data) {
    if (data.state === " " && data.zip === "" && data.city === " ") {
      return "";
    }
    if (data.city === " ") {
      return ` ${data.state.trim()}, ${data.zip}`;
    } else if (data.state === " " && data.zip === "") {
      return data.city;
    } else if (data.state === " ") {
      return `${data.city}, ${data.zip}`;
    }
    return `${data.city}, ${data.state.trim()}, ${data.zip}`;
  }
  return (
    <>
      <ApplicantViewDataContainer isApplicantView={props.isApplicantView}>
        <Grid container>
          <Grid
            item
            md={props.isLockView ? 12 : 6}
            style={{ borderRight: "1px solid lightgrey" }}
          >
            <RowText
              title={"Name"}
              value={
                formatName(applicant.first_name) +
                " " +
                formatName(applicant.middle_name) +
                " " +
                formatName(applicant.last_name)
              }
            />
            <RowText
              title={CommonStringForApplicant.designation}
              value={applicant.designation}
            />
            <RowText
              isemail={CommonString.true}
              title={CommonString.email}
              value={applicant.primary_email}
            />
            <RowText
              title={CommonStringForApplicant.address}
              value={getCityName(applicant)}
            />
            <RowText
              title={CommonStringForApplicant.tax_term}
              value={applicant.tax_term}
            />
            <RowText
              title={CommonStringForApplicant.comment}
              value={applicant.comment}
            />
            <RowText
              title={CommonStringForApplicant.work_authority}
              value={applicant.work_authority ?? ""}
            />
            <RowText
              color={CommonColor.secondary_color}
              title={CommonStringForApplicant.expected_pay_rate}
              value={applicant.payrate ?? CommonString.na}
            />
            <RowText
              color={CommonColor.secondary_color}
              title={CommonStringForApplicant.experience}
              value={applicant.experience}
            />
            <RowText
              title={CommonString.phone}
              value={formatPhoneNumber(applicant.phone)}
            />
            <RowText
              title={CommonStringForApplicant.source_by}
              value={applicant.source_by ?? ""}
            />
            <RowText
              color={CommonColor.secondary_color}
              title={CommonStringForApplicant.added_by}
              value={applicant.added_by ?? ""}
            />
            <RowTextHtml
              title={CommonStringForApplicant.skills}
              value={applicant.skills}
            />
            {submissionData.length === 0 ? (
              <SubmissionTitle>{CommonTitle.no_job}</SubmissionTitle>
            ) : (
              <>
                <SubmissionTitle>
                  {CommonTitle.connected_applicant}
                </SubmissionTitle>
                <Table>
                  <TableTbody>
                    <TableRow>
                      {submission_table_in_applicant.map((data) => (
                        <TableTh textAlignLeft={true}>{data}</TableTh>
                      ))}
                    </TableRow>
                    {submissionData.map((data) => (
                      <TableRow
                        onDoubleClick={() =>
                          props.onViewModal(VIEW_SUBMISSION, data)
                        }
                      >
                        <TableTd textAlignLeft={true}>
                          {data.position_title}
                        </TableTd>
                        <TableTd>{data.submitions_tax_term}</TableTd>
                        <TableTd>$ {data.submitions_payrate}</TableTd>
                        <TableTd>{data.recruiter_name}</TableTd>
                        <TableTd>
                          {moment(data._updated_at).format(DATE_FORMATE)}
                        </TableTd>
                        <TableTd>{data.status}</TableTd>
                      </TableRow>
                    ))}
                  </TableTbody>
                </Table>
              </>
            )}
          </Grid>
          <Grid item md={props.isLockView ? 12 : 6}>
            <DocContainer isVisible={true}>
              <div style={{ width: "100%" }}>
                {props.isLockView ? (
                  <div />
                ) : (
                  <ApplicantDocument height={"79vh"} is_visible_icon={false} />
                )}
              </div>
            </DocContainer>
          </Grid>
        </Grid>
      </ApplicantViewDataContainer>
    </>
  );
}
