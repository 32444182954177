import React, { useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import LevelDataTable from "../DataTable/LevelDataTable";
import { useSelector, useDispatch } from "react-redux";
import LevelController from "../../controller/LevelController";
import { SEARCH_LEVEL } from "../../Utils/enum";
import { onChangeSearchResult } from "../../feature/store/LevelReduxStore";
import InitialLoader from "../CommonComponet/Loader/InitialLoader";
import LoaderForTable from "../CommonComponet/LoaderForTable";

export default function LevelView() {
  const levelController = new LevelController();
  const dispatch = useDispatch();
  const levelList = useSelector((state) => state.counter.levelData);
  const search_result = useSelector(
    (state) => state.LevelStoreRedux.search_result
  );
  const isLoading = useSelector((state) => state.LevelStoreRedux.isLoading);
  const search_text = useSelector((state) => state.LevelStoreRedux.search_text);
  const initial_loader = useSelector((state) => state.counter.initial_loader);

  useEffect(() => {
    dispatch(onChangeSearchResult(levelList));
    levelController.getLevels();
  }, []);
  function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    levelController.onSearch(e.target.value);
  }
  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      <TopFixedMenu>
        <TopMenu
          placeholder={SEARCH_LEVEL}
          onChange={(e) => onChangeSearchText(e)}
        />
      </TopFixedMenu>
      <MainScreen>
        <LevelDataTable
          searchText={search_text}
          isLoading={isLoading}
          searchResult={search_result}
        />
      </MainScreen>
    </Container>
  );
}
