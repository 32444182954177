const CommonIcon = {
  user: "clarity:user-line",
  hierarchy: "system-uicons:hierarchy",
  search_people: "fluent:people-search-24-regular",
  people_group: "ph:users",
  handshake: "fa:handshake-o",
  plus: "ic:baseline-plus",
  people_checkmark: "fluent:people-checkmark-24-regular",
  file_text: "icomoon-free:file-text2",
  bag: "ph:bag-simple",
  dashboard: "radix-icons:dashboard",
  arrow_right: "ep:arrow-right",
  dash_icon: "dashicons:arrow-right-alt2",
  link: "cil:external-link",
  close: "ei:close",
  copy: "fa-regular:copy",
  modal_close: "fe:close",
  modal_edit: "fluent:edit-16-regular",
  modal_delete: "fluent:delete-16-regular",
  down_arrow: "dashicons:arrow-down",
  up_arrow: "dashicons:arrow-up",
  filter: "clarity:filter-solid",
  down_arrow_bold: "ep:arrow-down-bold",
  round_expand_ciecle_down: "ic:round-expand-circle-down",
  ep_close: "ep:close",
  down_filled: "ant-design:caret-down-filled",
  up_filled: "ant-design:caret-up-filled",
  notification: "clarity:notification-line",
  log_out: "uiw:logout",
  calender: "uil:calender",
  arrow_left: "akar-icons:arrow-left",
  right_left: "akar-icons:arrow-right",
  arrow_right_calender: "akar-icons:arrow-right",
  right_arrow_tool_list: "akar-icons:chevron-right",
  search: "carbon:search",
  delete_outline: "ant-design:delete-outlined",
  lock_line: "clarity:lock-line",
  download: "akar-icons:download",
  round_adjust: "ic:round-adjust",
  darkClose: "material-symbols:close-rounded",
  eye: "ant-design:eye-outlined",
  attachment: "teenyicons:attachment-outline",
  pdf: "bi:file-earmark-pdf",
  word: "bi:file-earmark-word",
  close_notification: "eva:close-outline",
  cake: "noto-v1:birthday-cake",
  email: "ic:round-mail-outline",
  call: "material-symbols:call-outline-sharp",
  edit: "material-symbols:edit-square-outline-rounded",
  check: "material-symbols:check-small-rounded",
  search_icon: "iconamoon:search-fill",
  map: "bx:map",
  bag: "basil:bag-outline",
  dot: "icon-park-outline:dot",
  table: "majesticons:table",
  graph: "mdi:graph-box-outline",
  left: "icon-park-outline:left",
  update: "material-symbols-light:update",
  list: "quill:list",
  visibility_off: "ic:visibility-off",
};

export { CommonIcon };
