import React from "react";
import { ErrorText } from "../../style/common.style";

export default function FormInput(props) {
  return (
    <>
      <label>{props.placeholder}</label>
      <div>
        <input
          style={{ height: props.multiline ? "40px" : "inherit" }}
          {...props}
          placeholder=""
          required={props.error}
        />
      </div>
      {props.error ? (
        <ErrorText>Please add {props.placeholder} </ErrorText>
      ) : (
        <></>
      )}
    </>
  );
}
{
  /* <FormField>
      <TextField
        fullWidth
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        label={props.placeholder}
        id="outlined-size-small"
        size="small"
        InputProps={{ style: { fontSize: 12 } }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        {...props}
        onKeyUp={props.onKeyUp}
        autoComplete={props.autoComplete ?? "off"}
        {...props}
        onBlur={props.onBlur}
      />
    </FormField> */
}
