import React from "react";
import { Grid, Typography } from "@material-ui/core";
import PlaceholderLoader from "../../CommonComponet/PlaceholderLoader";
import { TitleHR, TitleKey, TitleValue } from "../../../style/common.style";
import { CommonString } from "../../../Utils/CommonString";
import { checkNullString } from "../../../Utils/validation";

export default function RowText(props) {
  return (
    <div style={{padding:3}}>
      <Grid container {...props}>
        <Grid item xs={6} md={5}>
          <TitleKey color={props.color}>
            {checkNullString(props.title)}{" "}
          </TitleKey>
        </Grid>
        <Grid item xs={6} md={7}>
          <TitleValue color={props.color} isemail={props.isemail}>
            {props.value === null ? (
              <PlaceholderLoader />
            ) : props.value ? (
              props.value
            ) : (
              CommonString.three_dot
            )}
          </TitleValue>
        </Grid>
      </Grid>
      {props.nodivider ? <div /> : <TitleHR />}
    </div>
  );
}
