import React, { useEffect, useState } from "react";
import CheckBox from "../basic/CheckBox";
import { useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import { CommonIcon } from "../../Utils/CommonIcon";
import { FieldFilterIcon, FlexContainer } from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";
import {
  SUBMISSION_STATUS_FOR_MANAGER,
  all_report_card_list_with_value,
  report_card_list,
  report_card_list_with_value,
  report_card_list_without_value,
} from "../../Utils/constant";
import {
  changeInitialTableList,
  changeTableList,
  removeFromTableList,
} from "../../feature/store/ReportStore";
const ITEM_HEIGHT = 48;

export default function CustomizedTableField() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [formData, setFormData] = useState(report_card_list_with_value);

  useEffect(() => {
    setFormData(report_card_list_with_value);
    dispatch(changeInitialTableList(report_card_list_without_value));
  }, []);

  function handleInputChange(event) {
    const { name, checked } = event.target;
    const hasFalse = Object.values({ ...formData, [name]: checked }).some(
      (value) => value === false
    );
    if (hasFalse) {
      setFormData({ ...formData, all: false });
      dispatch(removeFromTableList("all"));
    } else {
      dispatch(changeInitialTableList(report_card_list));
    }
    if (name === "all") {
      setFormData(all_report_card_list_with_value);
      dispatch(changeInitialTableList(report_card_list));
      return;
    }
    setFormData(
      hasFalse
        ? { ...formData, [name]: checked, all: false }
        : { ...formData, [name]: checked }
    );
    if (checked) {
      dispatch(changeTableList(name));
      return;
    }
    dispatch(removeFromTableList(name));
  }
  //   console.log(formData);
  return (
    <>
      <FlexContainer>
        <FieldFilterIcon
          onClick={(event) => setAnchorEl(event.currentTarget)}
          icon={CommonIcon.list}
        />
      </FlexContainer>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: "0 10px",
          },
        }}
      >
        <>
          <CheckBox
            onClick={handleInputChange}
            value={formData.all}
            label={CommonString.all}
            name={"all"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.job}
            label={CommonString.positions}
            name={"job"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.applicant}
            label={CommonString.applicants}
            name={"applicant"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.in_touch}
            label={SUBMISSION_STATUS_FOR_MANAGER[0]}
            name={"in_touch"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.locked}
            label={SUBMISSION_STATUS_FOR_MANAGER[1]}
            name={"locked"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.review}
            label={SUBMISSION_STATUS_FOR_MANAGER[2]}
            name={"review"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.please_submit}
            label={SUBMISSION_STATUS_FOR_MANAGER[3]}
            name={"please_submit"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.submission}
            label={SUBMISSION_STATUS_FOR_MANAGER[4]}
            name={"submission"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.hold_internally}
            label={SUBMISSION_STATUS_FOR_MANAGER[5]}
            name={"hold_internally"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.rejected_internally}
            label={SUBMISSION_STATUS_FOR_MANAGER[6]}
            name={"rejected_internally"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.rejected_by_client}
            label={SUBMISSION_STATUS_FOR_MANAGER[7]}
            name={"rejected_by_client"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.interview}
            label={SUBMISSION_STATUS_FOR_MANAGER[8]}
            name={"interview"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.iv_no_show}
            label={SUBMISSION_STATUS_FOR_MANAGER[9]}
            name={"iv_no_show"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.offer}
            label={SUBMISSION_STATUS_FOR_MANAGER[10]}
            name={"offer"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.placement}
            label={SUBMISSION_STATUS_FOR_MANAGER[11]}
            name={"placement"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.bd_by_client}
            label={SUBMISSION_STATUS_FOR_MANAGER[12]}
            name={"bd_by_client"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.bd_by_consultant}
            label={SUBMISSION_STATUS_FOR_MANAGER[13]}
            name={"bd_by_consultant"}
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.project_end}
            label={SUBMISSION_STATUS_FOR_MANAGER[14]}
            name={"project_end"}
          />
        </>
      </Menu>
    </>
  );
}
