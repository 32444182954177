import React, { useState, useEffect } from "react";
import FormInput from "../CommonComponet/FormInput";
import CustomButton from "../CommonComponet/Button";
import LevelProvider from "../../services/provider/LevelProvider";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import { log } from "../../services/Logger/Logger";
import { formatPhoneNumber, ValidateEmail } from "../../services/Validate";
import RoundLoader from "../CommonComponet/RoundLoader";
import PasswordInputField from "../CommonComponet/PasswordInputField";
import CustomSwitch from "../CommonComponet/CustomSwitch";
import {
  ProfileContainer,
  ProfileUrl,
  RoundLoaderContainer,
  SwitchWithTitle,
} from "../../style/common.style";
import RecruiterController from "../../controller/RecruiterController";
import { PROFILE_BASEURL, TIME_FORMATE } from "../../Utils/constant";
import { Grid } from "@mui/material";
import { CommonLabel } from "../../Utils/CommonString";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { onChangeShift } from "../../feature/store/LevelReduxStore";
import userIcon from "../../assets/images/User.png";
import AddAccess from "../Access/AddAccess";
import { getAccessVisibility } from "../../Utils/utilitiFunction";
import { onChangeAccessDataById } from "../../feature/store/ReduxStore";
import CommonDatePicker from "../CommonComponet/CommonDatePicker";

export default function AddRecruiter(props) {
  var levelProvider = new LevelProvider();
  var recruiterController = new RecruiterController();
  const dispatch = new useDispatch();
  // const shift = useSelector((state) => state.LevelStoreRedux.shift);

  const [levelList, setLevelList] = useState([]);
  const [selectedValueLevel, setselectedValueLevel] = useState();
  const [name, setName] = useState();
  const [employeeID, setEmployeeID] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();
  const [levelID, setLevelID] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState("");
  const [password, setpassword] = useState(
    props.data ? props.data.password : ""
  );
  const [birth_date, setBirth_date] = useState(null);
  const [isRecruiter, setisRecruiter] = useState(false);
  const [profileUrl, setprofileUrl] = useState("");
  const [about, setAbout] = useState();
  const [shiftData, setShiftData] = useState([]);
  const [selectedShiftData, setSelectedShiftData] = useState();
  const [access_id, setAccess_id] = useState(null);
  const [accessData, setAccessData] = useState(null);

  useEffect(() => {
    getLevelData();
    getOfficeShiftTime();
  }, []);

  useEffect(() => {
    onPrefilledData();
  }, [shiftData]);

  function getLevelData() {
    levelProvider
      .getLevelList()
      .then((res) => {
        var temp = [];
        for (let index = 0; index < res.data.data.length; index++) {
          const element = res.data.data[index];
          var d = {
            designation: element.designation,
            name: element.name,
            id: element._id,
          };
          if (props.data && props.data.level_name === element.name) {
            setselectedValueLevel(d);
          }
          temp.push(d);
        }
        setLevelList(temp);

        setLevelID(temp[0]);
      })
      .catch((e) => {
        log(e);
      });
  }

  async function onPrefilledData() {
    // var RecruiterLocalData = localStorage.getItem('RecruiterLocalData');
    // if (RecruiterLocalData !== null) {
    //   setName(JSON.parse(RecruiterLocalData).name);
    //   setLevelID(JSON.parse(RecruiterLocalData).level_name);
    //   setEmployeeID(JSON.parse(RecruiterLocalData).employee_id);
    //   setEmail(JSON.parse(RecruiterLocalData).email);
    //   setPhoneNo(JSON.parse(RecruiterLocalData).phone);
    //   setpassword(JSON.parse(RecruiterLocalData).password);
    // }
    if (props.isUpdate && props.data != null) {
      var access_res = await recruiterController.getAccessById({
        user_id: props?.data?._id,
      });
      let access = getAccessVisibility(access_res);
      dispatch(onChangeAccessDataById(access));
      setAccessData(access_res);
      setAccess_id(access_res?._id);
      const filteredPreShift = shiftData
        ? shiftData.filter((time) => time._id === props.data.shift_id)
        : "";
      dispatch(onChangeShift(filteredPreShift[0]));
      setName(props.data.name);
      setLevelID(props.data.level_name);
      setEmployeeID(props.data.employee_id);
      setEmail(props.data.email);
      setPhoneNo(formatPhoneNumber(props.data.phone));
      setpassword(props.data.password);
      setisRecruiter(props.data.is_recruiter);
      setprofileUrl(props.data.profile_image_url);
      setBirth_date(props.data.birthday);
      setAbout(props.data.about);
    }
  }

  function onAddRecruiter() {
    if (!validate()) {
      return;
    }
    var filteredShift = shiftData.filter((time) => time.shift_type === "US");
    var phoneNumber =
      phoneNo !== undefined ? phoneNo.replace(/[^\d\+]/g, "") : "";
    var data = {
      name: name,
      level_id: selectedValueLevel ? selectedValueLevel.id : "",
      employee_id: employeeID ? employeeID.toUpperCase() : "",
      level_name: selectedValueLevel ? selectedValueLevel.name : "",
      email: email,
      phone: phoneNumber,
      password: password,
      is_recruiter: isRecruiter ? 1 : 0,
      profile_image_url: profileUrl ? profileUrl : "",
      birthday: birth_date,
      about: about ?? "",
      shift_id:
        selectedShiftData === null || selectedShiftData === undefined
          ? filteredShift[0]._id
          : selectedShiftData._id,
      access_id: access_id,
    };
    props.onSubmitData(data);
  }

  function validate() {
    if (name === null || name === undefined) {
      setERROR_NAME("NAME");
      return false;
    }
    if (employeeID === null || employeeID === undefined) {
      setERROR_NAME("EMPLOYEE_ID");
      return false;
    }
    if (selectedValueLevel === null || selectedValueLevel === undefined) {
      setERROR_NAME("LEVEL_NAME");
      return false;
    }
    if (birth_date === undefined || birth_date === null || birth_date === "") {
      setERROR_NAME("DATE_OF_BIRTH");
      return false;
    }
    // if (phoneNo === null || !ValidatePhone(phoneNo) || phoneNo === undefined) {
    //   setERROR_NAME("PHONE_NUMBER");
    //   return false;
    // }
    if (email === null || !ValidateEmail(email) || email === undefined) {
      setERROR_NAME("EMAIL");
      return false;
    }
    if (password === null || password === undefined) {
      setERROR_NAME("PASSWORD");
      return false;
    }

    setERROR_NAME("");
    return true;
  }
  const handleInputPhone = (e) => {
    // setPhoneNo(e.target.value);
    // setERROR_NAME("");
    setPhoneNo(formatPhoneNumber(e.target.value));
  };
  async function getOfficeShiftTime() {
    var res = await recruiterController.getOfficeTime();
    setShiftData(res);
  }
  function onUploadProfile(e) {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    var res = recruiterController
      .UploadRecruiterProfile(formData)
      .then((res) => {
        setprofileUrl(res.data.data.url);
      })
      .catch((e) => {
        log(e);
      });
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <input
            name="Profile"
            type={"file"}
            onChange={(e) => onUploadProfile(e)}
          />
          <ProfileContainer>
            {profileUrl !== "" ? (
              <ProfileUrl
                profileUrl={profileUrl}
                src={PROFILE_BASEURL + profileUrl}
              />
            ) : (
              <ProfileUrl profileUrl={userIcon} src={userIcon} />
            )}
          </ProfileContainer>
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12} spacing={5}>
              <FormInput
                error={ERROR_NAME === "NAME"}
                value={name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                  setERROR_NAME("");
                }}
                placeholder="Name *"
              />
              <FormInput
                required
                error={ERROR_NAME === "PHONE_NUMBER"}
                value={phoneNo || ""}
                onChange={(e) => {
                  handleInputPhone(e);
                }}
                placeholder="Phone No. *"
              />
              <FormInput
                autoComplete="off"
                error={ERROR_NAME === "EMAIL"}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setERROR_NAME("");
                }}
                value={email || ""}
                placeholder="Email *"
              />
              <PasswordInputField
                type={props.isUpdate ? "text" : "password"}
                onChange={(e) => setpassword(e)}
                value={password || ""}
                placeholder="Password *"
              />
              <SwitchWithTitle>
                {"Recruiter\xa0\xa0\xa0:\xa0\xa0\xa0"}
                <CustomSwitch
                  checked={isRecruiter}
                  onChange={(e) => setisRecruiter(e.target.checked)}
                />
              </SwitchWithTitle>
            </Grid>
            <Grid item md={6} xs={12}>
              {levelList.length === 0 ? (
                <div>
                  <SelectTextFields disabled={true} placeholder=" " />
                  <RoundLoaderContainer>
                    <RoundLoader />
                  </RoundLoaderContainer>
                </div>
              ) : (
                <SelectTextFields
                  error={ERROR_NAME === "LEVEL_NAME"}
                  placeholder="Level Name *"
                  value={selectedValueLevel}
                  onChange={(e) => setselectedValueLevel(e)}
                  options={levelList}
                  getOptionLabel={(option) =>
                    option.name + " ( " + option.designation + " ) "
                  }
                />
              )}
              <FormInput
                error={ERROR_NAME === "EMPLOYEE_ID"}
                value={employeeID || ""}
                onChange={(e) => {
                  setEmployeeID(e.target.value);
                  setERROR_NAME("");
                }}
                placeholder="Prexelite ID *"
              />
              <CommonDatePicker
                error={ERROR_NAME === "DATE_OF_BIRTH"}
                value={birth_date}
                onChange={(date) => {
                  setBirth_date(date);
                  setERROR_NAME("");
                }}
                name={CommonLabel.birth_date}
              />
              <SelectTextFields
                placeholder="Shift Time *"
                value={selectedShiftData}
                onChange={(value) => setSelectedShiftData(value)}
                options={shiftData}
                getOptionLabel={(option) =>
                  option.shift_type +
                  " ( " +
                  moment(option.in_time).format(TIME_FORMATE) +
                  " - " +
                  moment(option.out_time).format(TIME_FORMATE) +
                  " ) "
                }
              />
              <FormInput
                multiline
                value={about || ""}
                onChange={(e) => setAbout(e.target.value)}
                placeholder="About"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {props.isUpdate ? <AddAccess accessData={accessData} /> : <div />}
      <CustomButton
        onClick={onAddRecruiter}
        name={props.isUpdate ? "Update" : "Add"}
      />
    </>
  );
}
